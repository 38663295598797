import { ChangeDetectionStrategy, Component, Inject, Signal } from "@angular/core";
import { AppConfig, APP_CONFIG } from "app/core";

import { AnimationConfigWithData, AnimationItem } from "lottie-web";
import { AnimationOptions, LottieComponent as NgxLottieComponent } from "ngx-lottie";
import { mergeMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AnimationOptionsFactory } from "./animation-options.factory";
import { AnimationApiClient, AnimationQueueFactory, AnimationRequest } from "app/animation";
import { toSignal } from "@angular/core/rxjs-interop";

/** Displays an animated logo */
@Component({
  selector: "fof-lottie",
  templateUrl: "./template.html",
  providers: [
    AnimationQueueFactory,
    AnimationOptionsFactory,
    {
      provide: AnimationApiClient,
      useFactory: (httpClient: HttpClient) =>
        new AnimationApiClient<AnimationConfigWithData>(httpClient),
      deps: [HttpClient],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgxLottieComponent],
})
export class LottieComponent {
  protected options: Signal<AnimationOptions | null>;
  private playHalf: boolean;

  // difficult to test and test is not quite relevant, so not tested
  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    animationQueueFactory: AnimationQueueFactory,
    animationOptionsFactory: AnimationOptionsFactory
  ) {
    this.playHalf = !!config.headerLogoAnimations?.play_half;
    this.options = toSignal(
      animationQueueFactory
        .makeQueue()
        .pipe(
          mergeMap((request: AnimationRequest) => animationOptionsFactory.makeOptions(request))
        ),
      { initialValue: null }
    );
  }

  animationCreated(animationItem: AnimationItem): void {
    if (this.playHalf) {
      // ONLY FOR POP
      // play only the first half of the anim, forward and then back, because the pop anims
      // are corrupted at their end, so we can't play them from start to end.
      const middle = Math.floor(animationItem.totalFrames / 2);
      animationItem.playSegments([0, middle], true);
      animationItem.playSegments([middle, 0], false);
    }
    animationItem.play();
  }
}
