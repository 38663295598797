export interface ScriptLoadStatus {
  error: boolean;
  message: string;
}

/**
 * Loads a script denoted by the passed URL.
 * Not testable
 */
export function loadScript(url: string): Promise<ScriptLoadStatus> {
  return new Promise((resolve, _) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.onload = () => {
      resolve({ error: false, message: "" });
    };
    script.onerror = () => {
      resolve({ error: true, message: url });
    };
    document.getElementsByTagName("head")[0].appendChild(script);
  });
}
