import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { PersonalInfo } from "app/common";
import { ChatBoxContentSwitcher } from "../../common/chat-box-content.switcher";

/** A store to retrieve personal information from the user. Only to be used for the switch to an external chat. */
@Injectable({ providedIn: "root" })
export class PersonalInfoStore {
  private value$ = new Subject<PersonalInfo>();

  constructor(private chatBoxContentSwitcher: ChatBoxContentSwitcher) {}

  /** @return An observable to personal information on a user */
  get(): Observable<PersonalInfo> {
    return this.value$.asObservable();
  }

  /** Publishes new personal information retrieved from the user */
  publish(infos: PersonalInfo) {
    this.value$.next(infos);
    this.chatBoxContentSwitcher.release();
  }

  /** Requests new personal information from the user  */
  request() {
    this.chatBoxContentSwitcher.request("personal-info");
  }

  /** Cancels the request for personal information */
  cancelRequest() {
    this.chatBoxContentSwitcher.release();
  }
}
