import { Inject, Injectable } from "@angular/core";
import { SwitcherExtraInformation, EXTERNAL_SWITCHER } from "app/common";
import { ChatSwitchService } from "./switch.service";
import { ChatLifecycleService } from "../chat-lifecycle.service";
import { Subject } from "rxjs";

/** Synchronous implementation of a switch to an external chat. */
@Injectable({ providedIn: "root" })
export class ChatSwitchSyncService implements ChatSwitchService {
  constructor(
    @Inject(EXTERNAL_SWITCHER)
    private externalSwitcher: Subject<SwitcherExtraInformation>,
    private chatLifecycleService: ChatLifecycleService
  ) {}

  /** Switches from the chatbot to an external chat synchronously. */
  switchChatOff(extraInfo: SwitcherExtraInformation = {}): void {
    this.externalSwitcher.next(extraInfo);
    this.chatLifecycleService.notifySwitchedOff();
  }
}
