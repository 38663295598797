import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { APP_CONFIG, AppConfig, AppHostService } from "app/core";
import { UserStore } from "app/user";
import { MaintenanceApiClient } from "./maintenance.apiclient";
import { MaintenanceStatus } from "./maintenance-status.model";
import { AvailabilityState, AvailabilityStore } from "./availability.store";
import { USER_STORE } from "app/user/di-tokens";

/**
 * An implementation for which the application is available only on certain criteria,
 * defined by the configuration for the bot and the maintenance status.
 * Note that it makes no sense to use this implementation if availability should not be
 * checked for the application instance.
 */
@Injectable()
export class AvailabilityStoreMaintenanceImpl implements AvailabilityStore {
  private _inMaintenance: boolean = false;
  private _isOnline: boolean = false;
  private _showAnyway: boolean = false;
  private _available$ = new BehaviorSubject<AvailabilityState | undefined>(undefined);

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    hostService: AppHostService,
    private maintenanceApiClient: MaintenanceApiClient,
    @Inject(USER_STORE) private userStore: UserStore
  ) {
    // show the bot anyway if we're on the specific page config.whereToAlwaysShow
    // or if it is the test bot
    this._showAnyway =
      (this.config.whereToAlwaysShow &&
        hostService.currentUrl.includes(this.config.whereToAlwaysShow)) ||
      hostService.isTestBot;
  }

  /**
   * @return Whether the application is available for the current user or
   *         undefined if the state has not been computed yet
   */
  get available$(): Observable<AvailabilityState> {
    return this._available$.asObservable();
  }

  /** Initializes the availability state, according to the maintenance state retrieved from the backend */
  initialize() {
    if (!this.userStore.isMissingRequiredUser()) {
      this.maintenanceApiClient
        .retrieveStatus()
        .pipe(tap())
        .subscribe({
          next: (response: MaintenanceStatus) => {
            if (this.config.hasMaintenance) {
              this._inMaintenance = response.in_maintenance;
            }
            this._isOnline = response.is_online;
            const newIsAvailable = !this._inMaintenance && (this._isOnline || this._showAnyway);
            this._available$.next(newIsAvailable ? "yes" : "no");
          },
          error: (err) => {
            this._available$.next("no");
            console.error("Unable to fetch maintenance status", err);
          },
        });
    } else {
      this._available$.next("no");
      console.error("Missing required user");
    }
  }
}
