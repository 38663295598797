import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { SuggestionComponent } from "../suggestion-component/index";
import { NgClass, NgFor } from "@angular/common";
import { RequestFocusDirective } from "app/core/ui";
import { SuggestionsMessage } from "app/chat/common/message.model";

/** A component displaying a menu of buttons representing suggestions */
@Component({
  selector: "fof-suggestions-menu",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, SuggestionComponent, NgClass, RequestFocusDirective],
})
export class SuggestionsMenuComponent {
  @HostBinding("class") hostClasses = "d-flex justify-content-start flex-wrap";
  @HostBinding("style") hostStyles =
    "padding-left: var(--fof-bot-msg-left-space); list-style-type: none; margin-block-start: 0; margin-block-end: 0; margin-inline-start: 0; margin-inline-end: 0; padding-inline-start: 0;";

  @Input()
  public message!: SuggestionsMessage;
}
