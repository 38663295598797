import { Signal, WritableSignal, signal } from "@angular/core";

/**
 * A service providing facilities to temporarily lock the conversation from a user perspective:
 * while the conversation is locked, the user cannot send messages.
 */
export class LockUtil {
  private readonly _locked: WritableSignal<boolean>;

  constructor(initialValue: boolean) {
    this._locked = signal(initialValue);
  }

  /** @return Whether the lock is activated */
  get locked(): Signal<boolean> {
    return this._locked;
  }

  /** Requests the lock to be activated */
  lock() {
    if (this._locked() !== true) {
      this._locked.set(true);
    }
  }

  /** Requests the lock to be deactivated */
  unlock() {
    if (this._locked() !== false) {
      this._locked.set(false);
    }
  }
}
