import { Injectable } from "@angular/core";
import { IncomingConversationBlock, ExecutableContent } from "./connector";

/**
 * A facility to extract script IDs from backend messages.
 */
@Injectable({ providedIn: "root" })
export class ScriptsExtractor {
  /**
   * @return All the scripts to be executed (denoted by their ID) on the reception of
   *         the passed conversation block. Suggestion scripts are not included.
   */
  extractScriptIds(block: IncomingConversationBlock): string[] {
    return [block.user_message, ...block.bot_message.answers]
      .filter((message) => message.content?.hasOwnProperty("script"))
      .map((message) => (message as { content: ExecutableContent }).content.script)
      .flatMap((script) => (Array.isArray(script) ? script : [script]))
      .filter((script) => !!script) as string[];
  }
}
