import { Directive, HostBinding, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig } from "app/core";

/** Displays an image in a conversation, allowing to open a popup containing the image */
@Directive({
  selector: "img[botAvatar]",
  standalone: true,
})
export class BotAvatarDirective {
  @HostBinding("class") hostClasses = "ratio ratio-1x1";
  @HostBinding("style") hostStyles =
    " width: var(--fof-bot-msg-avatar-width);  margin-right: var(--fof-bot-msg-avatar-right-margin);";
  @HostBinding("attr.aria-hidden") ariaHiddenAttribute = "true";
  @HostBinding("attr.src") avatarUrl: string;

  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    this.avatarUrl = config.messageAvatarUrl;
  }
}
