import { ChangeDetectionStrategy, Component, HostBinding, Signal } from "@angular/core";
import { NgFor, NgIf } from "@angular/common";
import { HeaderLogoComponent } from "../logo-component";
import { ChatOpenStore } from "app/chat";
import { IconButtonComponent } from "app/core/ui";
import { HeaderActionsService } from "./header-actions.service";
import { HeaderAction } from "./header-action.model";

/** The header of the chat box. */
@Component({
  selector: "header[main]",
  templateUrl: "./template.html",
  styleUrls: ["./style.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, HeaderLogoComponent, IconButtonComponent],
  providers: [HeaderActionsService],
})
export class HeaderComponent {
  @HostBinding("class") hostClasses = "rounded-top-5 d-flex align-items-center gap-3 p-3";

  /** Styles to be applied to action buttons */
  protected buttonsStyles = {
    color: "var(--fof-header-button-color)",
    bgColor: "var(--fof-header-button-bg-color)",
    borderColor: "var(--fof-header-button-border-color)",
    colorOnHover: "var(--fof-header-button-hover-color)",
    bgColorOnHover: "var(--fof-header-button-hover-bg-color)",
    borderColorOnHover: "var(--fof-header-button-hover-border-color)",
  };
  protected actions: Signal<HeaderAction[]>;

  constructor(
    protected readonly chatOpenStore: ChatOpenStore,
    headerActionsService: HeaderActionsService
  ) {
    this.actions = headerActionsService.actions;
  }
}
