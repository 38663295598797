import { EnvironmentInjector, InjectionToken, inject, runInInjectionContext } from "@angular/core";
import {
  BotIncomingMessageConverter,
  SupportedSatisfactionLevelResultType,
  SupportedSatisfactionLevelType,
  UserIncomingMessageConverter,
} from "./message-converter.model";
import { UserTextMessageConverter } from "./user-text-message.converter";
import { InteractionResultMessageConverter } from "./user-interaction-result-message.converter";
import { BotImageMessageConverter } from "./bot-image-message.converter";
import { SatisfactionLevelMessageConverter } from "./satisfaction-level-message.converter";
import { SatisfactionEmojiMessageConverter } from "./satisfaction-emoji-message.converter";
import { SatisfactionCommentMessageConverter } from "./satisfaction-comment-message.converter";
import { SuggestionsMessageConverter } from "./suggestions-message.converter";
import { BotTextMessageConverter } from "./bot-text-message.converter";
import { UserSatisfactionCommentMessageConverter } from "./user-satisfaction-comment-message.converter";

/**
 * The ordered list of the labels of the available satisfaction levels.
 * Beware! Should not be evaluated while the application is still being initialized.
 */
export const SATISFACTION_LEVEL_LABELS = new InjectionToken<readonly string[]>(
  "SATISFACTION_LEVEL_LABELS",
  {
    factory: () =>
      Object.freeze([
        $localize`:@@satisfaction.level.1:`,
        $localize`:@@satisfaction.level.2:`,
        $localize`:@@satisfaction.level.3:`,
        $localize`:@@satisfaction.level.4:`,
        $localize`:@@satisfaction.level.5:`,
      ]),
  }
);

/**
 * The ordered list of the labels of the available satisfaction levels.
 * Beware! Should not be evaluated while the application is still being initialized.
 */
export const SATISFACTION_LEVEL_RESULTS_LABELS = new InjectionToken<readonly string[]>(
  "SATISFACTION_LEVEL_RESULTS_LABELS",
  {
    factory: () =>
      Object.freeze([
        $localize`:@@satisfaction.result.level.1:`,
        $localize`:@@satisfaction.result.level.2:`,
        $localize`:@@satisfaction.result.level.3:`,
        $localize`:@@satisfaction.result.level.4:`,
        $localize`:@@satisfaction.result.level.5:`,
      ]),
  }
);

/**
 * The supported satisfaction level results and their related labels.
 * Beware! Should not be evaluated while the application is still being initialized.
 * Hence, the lazy initialization.
 */
export const SUPPORTED_SATISFACTION_LEVELS_FOR_RESULT = new InjectionToken<
  () => readonly SupportedSatisfactionLevelResultType[]
>("SUPPORTED_SATISFACTION_LEVELS", {
  factory: () => {
    const environmentInjector = inject(EnvironmentInjector);
    let result: readonly SupportedSatisfactionLevelResultType[] = [];
    const resultLoader = () =>
      runInInjectionContext(environmentInjector, () => {
        const labels = inject(SATISFACTION_LEVEL_RESULTS_LABELS);
        result = Object.freeze([
          // pop
          { text: "⭐", intent: "intent_no", label: labels[0] },
          { text: "⭐⭐", intent: "intent_no", label: labels[1] },
          { text: "⭐⭐⭐", intent: "intent_yes", label: labels[2] },
          { text: "⭐⭐⭐⭐", intent: "intent_yes", label: labels[3] },
          { text: "⭐⭐⭐⭐⭐", intent: "intent_yes", label: labels[4] },
          // arole
          { text: "⭐", intent: "evaluation_down_reponse", label: labels[0] },
          { text: "⭐⭐", intent: "evaluation_down_reponse", label: labels[1] },
          { text: "⭐⭐⭐", intent: "evaluation_down_reponse", label: labels[2] },
          { text: "⭐⭐⭐⭐", intent: "evaluation_up_reponse", label: labels[3] },
          { text: "⭐⭐⭐⭐⭐", intent: "evaluation_up_reponse", label: labels[4] },
          // douane
          { text: "etoile1", intent: "evaluation_down_reponse", label: labels[0] },
          { text: "etoile2", intent: "evaluation_down_reponse", label: labels[1] },
          { text: "etoile3", intent: "evaluation_up_reponse", label: labels[2] },
          { text: "etoile4", intent: "evaluation_up_reponse", label: labels[3] },
          { text: "etoile5", intent: "evaluation_up_reponse", label: labels[4] },
        ]);
      });
    return () => {
      // lazy initialization, so that the labels are loaded once the translation labels are loaded by Angular I18N.
      if (result.length === 0) {
        resultLoader();
      }
      return result;
    };
  },
});

export const SUPPORTED_SATISFACTION_LEVELS = new InjectionToken<
  readonly SupportedSatisfactionLevelType[]
>("SUPPORTED_SATISFACTION_LEVELS", {
  factory: () => {
    return Object.freeze([
      // arole
      { text: "⭐", intent: "evaluation_down_reponse" },
      { text: "⭐⭐", intent: "evaluation_down_reponse" },
      { text: "⭐⭐⭐", intent: "evaluation_down_reponse" },
      { text: "⭐⭐⭐⭐", intent: "evaluation_up_reponse" },
      { text: "⭐⭐⭐⭐⭐", intent: "evaluation_up_reponse" },
      // douane
      { text: "etoile1", intent: "evaluation_down_reponse" },
      { text: "etoile2", intent: "evaluation_down_reponse" },
      { text: "etoile3", intent: "evaluation_up_reponse" },
      { text: "etoile4", intent: "evaluation_up_reponse" },
      { text: "etoile5", intent: "evaluation_up_reponse" },
    ]);
  },
});

/**
 * The converters to be used for converting incoming messages that were authored by the user.
 * Beware! The order of the converters is important. Do not change it if your are not absolutely
 * sure of what you are doing.
 */
export const INCOMING_USER_MESSAGE_CONVERTERS = new InjectionToken<
  Array<UserIncomingMessageConverter>
>("INCOMING_USER_MESSAGE_CONVERTERS", {
  factory: () => [
    new UserSatisfactionCommentMessageConverter(),
    new InteractionResultMessageConverter(inject(SUPPORTED_SATISFACTION_LEVELS_FOR_RESULT)),
    new UserTextMessageConverter(),
  ],
});

/**
 * The converters to be used for converting incoming messages that are authored by the bot.
 * Beware! The order of the converters is important. Do not change it if your are not absolutely
 * sure of what you are doing.
 */
export const INCOMING_BOT_MESSAGE_CONVERTERS = new InjectionToken<
  Array<BotIncomingMessageConverter>
>("INCOMING_BOT_MESSAGE_CONVERTERS", {
  factory: () => [
    new BotImageMessageConverter(),
    new SatisfactionLevelMessageConverter(inject(SUPPORTED_SATISFACTION_LEVELS)),
    new SuggestionsMessageConverter(),
    new SatisfactionEmojiMessageConverter(),
    new SatisfactionCommentMessageConverter(),
    new BotTextMessageConverter(),
  ],
});
