import { BotTextMessage, UserTextMessage } from "app/chat";
import { DimeloMessage } from "./dimelo.model";
import { PersonalInfo } from "app/common";
import { Injectable } from "@angular/core";

/** Generates  server messages to Dimelo messages */
@Injectable({ providedIn: "root" })
export class DimeloMessageFactory {
  /** @return Messages ready to be sent to Dimelo: the last user message is engaged */
  arrayFrom(
    messages: Array<UserTextMessage | BotTextMessage>,
    personalInfo: PersonalInfo | null
  ): DimeloMessage[] {
    const result = messages.map((message) => ({
      body: this.replaceSpecialCharacters(message.text),
      agent: message instanceof BotTextMessage,
      engage: false,
    }));

    const lastUserMessageIndex = this.findLastIndex(result, (message) => !message.agent);
    if (lastUserMessageIndex === -1) {
      return result;
    }
    if (personalInfo) {
      result.splice(lastUserMessageIndex, 0, this.from(personalInfo));
    }
    return result;
  }

  /** @return A Dimelo message containing personal information */
  from(personalInfo: PersonalInfo): DimeloMessage {
    const personalInfoString = `
        Mon nom : ${personalInfo.name}
        \n Mon prénom : ${personalInfo.firstname}
        \n Mon matricule : ${personalInfo.id}
        \n Mon employeur: ${personalInfo.employer}
        \n Mon email: ${personalInfo.email}`;

    return {
      body: personalInfoString,
      agent: false,
      engage: false,
    };
  }

  /** @return The text without /&nbsp caracters */
  private replaceSpecialCharacters(text: string): string {
    return text
      .replace(/&nbsp/gi, " ")
      .replace(/&agrave;/gi, "à")
      .replace(/&ocirc;/gi, "ô")
      .replace(/&eacute;/gi, "é")
      .replace(/&egrave;/gi, "è")
      .replace(/&ccedil;/gi, "ç")
      .replace(/&ugrave;/gi, "ù");
  }

  // TODO replace by native impl with core-js polyfill when migrating to angular 16
  private findLastIndex<T>(
    array: Array<T>,
    predicate: (value: T, index: number, obj: T[]) => boolean
  ) {
    for (let i = array.length - 1; i >= 0; i--) {
      if (predicate(array[i], i, array)) {
        return i;
      }
    }
    return -1;
  }
}
