import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input } from "@angular/core";
import { UserTextMessage } from "../message.model";
import { NgIf, NgClass, NgStyle } from "@angular/common";
import { APP_CONFIG, AppConfig, CleanHtmlPipe, IconizePipe } from "app/core";
import { LineBreakPipe } from "app/core/util/line-break.pipe";

/**
 * Displays a text message issued by the user.
 * - The message is displayed on the right with no special spacer on the right
 * - User written messages have corners similar to the last message of a bot message, but oriented to the right
 * - Messages resulting from an interaction have rounded corners like a button
 * - Background color: emphasized color or color provided in the message
 * - No interaction possible for these messages
 */
@Component({
  selector: "article[userMessage]",
  templateUrl: "./template.html",
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, CleanHtmlPipe, IconizePipe, LineBreakPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTextMessageComponent {
  /**
   * The classes to be applied to the host element, overriding any classes
   * defined in the parent component
   */
  @HostBinding("class") hostClasses = "text-end ms-5";
  @HostBinding("attr.aria-label") hostAriaLabel = $localize`:@@chat.message:`;

  /** @return The text to be displayed. */
  protected text = "";
  protected message!: UserTextMessage;
  /** @return The style of the message, if it is an interaction */
  protected style!: { [klass: string]: any };

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  @Input()
  set userMessage(newValue: UserTextMessage) {
    this.message = newValue;
    this.computeStyle();
  }

  private computeStyle() {
    this.style = {
      "background-color":
        this.config.hasCustomButtonColor && this.message?.color
          ? this.message.color
          : "var(--fof-primary-bg-color)",
    };
  }
}
