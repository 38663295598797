<fof-lottie
  data-testid="logo-lottie"
  *ngIf="hasAnimation; else imageLogo"
  class="d-block"
  style="
    height: var(--fof-header-logo-height);
    width: var(--fof-header-logo-width);
    margin-left: var(--fof-lottie-left-margin);
    margin-right: var(--fof-lottie-right-margin);
    transform: translateX(var(--fof-lottie-translate-x)) translateY(var(--fof-lottie-translate-y))
      scale(var(--fof-lottie-scale));
    background: var(--fof-lottie-background);
  "
/>
<ng-template #imageLogo>
  <img
    data-testid="logo-img"
    [src]="logoUrl"
    alt="-"
    i18n-alt="@@header.logo"
    style="
      height: var(--fof-header-logo-height);
      width: var(--fof-header-logo-width);
      margin-right: var(--fof-header-logo-right-margin);
      transform: translateX(var(--fof-header-logo-translate-x))
        translateY(var(--fof-header-logo-translate-y)) scale(var(--fof-header-logo-scale));
    "
  />
</ng-template>
