/// <reference types="@angular/localize" />

import { environment } from "environments/environment";
import { AppComponent } from "app/app.component";
import { setupChat } from "app/chat";
import { enableProdMode, importProvidersFrom } from "@angular/core";
import { provideDimelo } from "app/dimelo";
import { provideI18n } from "app/core";
import { FormsModule } from "@angular/forms";
import { provideAnimations } from "@angular/platform-browser/animations";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { UserHttpInterceptor } from "app/user";
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from "@angular/common/http";
import { provideLottieOptions } from "ngx-lottie";
import player from "lottie-web";

/* Check if the main component's element (bot-root) exists in the DOM
  Otherwise the whole angular bootstrap will fail
  the element is not found when Angular first tries to bootstrap
  inside the website's context then it works inside the iframe's context */
if (document.querySelector("bot-root")) {
  if (environment.production) {
    enableProdMode();
  } else {
    console.log("environment : ", environment);
  }

  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(BrowserModule, FormsModule),
      { provide: HTTP_INTERCEPTORS, useClass: UserHttpInterceptor, multi: true },
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      //
      provideI18n(),
      //
      provideDimelo(),
      provideLottieOptions({ player: () => player }),
      setupChat(),
    ],
  });
}
