/** Dependency injection tokens */
import { InjectionToken, inject } from "@angular/core";
import { ChatSwitchService } from "./switch.service";
import { APP_CONFIG } from "app/core";
import { ChatSwitchWithPersonalInfoService } from "./switch-with-personal-info.service";
import { ChatSwitchSyncService } from "./switch-sync.service";
import { PersonalInfoStore } from "./personal-info";

// Token for the regular chat switch service
export const CHAT_SWITCH_SERVICE = new InjectionToken<ChatSwitchService>("CHAT_SWITCH_SERVICE", {
  factory: () => {
    const config = inject(APP_CONFIG);
    return config.dimeloNeedsPersonalInfo
      ? new ChatSwitchWithPersonalInfoService(
          inject(PersonalInfoStore),
          inject(ChatSwitchSyncService)
        )
      : inject(ChatSwitchSyncService);
  },
});
// Delay to be used for the delayed switch service
export const CHAT_SWITCH_DELAY = new InjectionToken<number>("CHAT_SWITCH_DELAY", {
  factory: () => 4000,
});
