import { Injectable } from "@angular/core";
import { UserRightsStore } from "app/user";
import { OutgoingMessageService } from "app/chat/outgoing";
import { ChatLifecycleService } from "../../chat-lifecycle.service";
import { Suggestion } from "app/chat/incoming";
import { MessagesStore } from "../messages.store";

/** A service to execute the behavior associated to a suggestion accepted by the user */
@Injectable({ providedIn: "root" })
export class SuggestionExecutor {
  /** An object associating a suggestion identifier to the code to be executed when
   * the suggestion is accepted */
  actions = {
    close_chat: (suggestion: Suggestion) => {
      this.sendSuggestion(suggestion);
      this.chatLifecycleService.shutdown();
    },
    rgpd_refused: () => this.chatLifecycleService.shutdown(),
    rgpd_accepted: (suggestion: Suggestion) => {
      this.sendSuggestion(suggestion);
      this.userRightsStore.canWrite = true;
    },
    enable_user_typing: () => {
      this.userRightsStore.canWrite = true;
    },
    question_evaluation_deblock: (suggestion: Suggestion) => {
      this.sendSuggestion(suggestion);
      this.userRightsStore.canWrite = true;
    },
  };

  constructor(
    private outgoingMessageService: OutgoingMessageService,
    private chatLifecycleService: ChatLifecycleService,
    private userRightsStore: UserRightsStore,
    private messagesStore: MessagesStore
  ) {}

  private sendSuggestion(suggestion: Suggestion) {
    this.outgoingMessageService.sendAcceptedSuggestionMessage(suggestion);
    this.messagesStore.nextTemporaryUserMessage(suggestion.text, false, suggestion.color);
  }

  /** Executes the behavior associated to the passed suggestion */
  execute(suggestion: Suggestion): void {
    const script = suggestion.script;
    if (script && this.actions.hasOwnProperty(script)) {
      const actionKey = script as keyof typeof this.actions;
      this.actions[actionKey](suggestion);
    } else {
      this.sendSuggestion(suggestion);
    }
  }
}
