import { UserIncomingMessage, UserTextIncomingMessage } from "../connector";
import { ConversationMessage, UserTextMessage } from "../../common/message.model";
import { UserIncomingMessageConverter } from "./message-converter.model";

/** Converts a simple user text message */
export class UserTextMessageConverter implements UserIncomingMessageConverter {
  supports(incomingMessage: UserIncomingMessage): boolean {
    return incomingMessage?.content_type === "text" && !!incomingMessage.content?.value;
  }
  convert(incomingMessage: UserIncomingMessage): ConversationMessage {
    return UserTextMessage.makeTextMessage(
      (incomingMessage as UserTextIncomingMessage).content.value
    );
  }
}
