import { ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { NgFor } from "@angular/common";
import { SatisfactionEmojisMessage, SatisfactionEmoji } from "app/chat/common/message.model";
import { LabeledLargeButtonComponent } from "app/core/ui/labeled-large-button-component";
import { OutgoingMessageService } from "app/chat/outgoing";
import { MessagesStore } from "../../messages.store";

/**
 * The component displaying the list of the emojis that can be used
 * by the user to evaluation his satisfaction regarding the whole conversation
 */
@Component({
  selector: "fof-satisfaction-emoji",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, LabeledLargeButtonComponent],
})
export class SatisfactionEmojiComponent {
  protected emojis = signal<SatisfactionEmoji[]>([]);

  constructor(
    private outgoingMessageService: OutgoingMessageService,
    private messagesStore: MessagesStore
  ) {}

  @Input() set message(newValue: SatisfactionEmojisMessage) {
    const orderForEmojis = ["sad", "mid", "happy"];
    const sortedEmojis = [];
    for (let type of orderForEmojis) {
      for (let emoji of newValue.emojis) {
        if (emoji.type === type) {
          sortedEmojis.push(emoji);
        }
      }
    }
    this.emojis.set(sortedEmojis);
  }

  /** Executes the expected behavior for the passed suggestion */
  onSelect(emoji: SatisfactionEmoji): void {
    this.outgoingMessageService.sendEmojiSatisfactionMessage(emoji);
    this.messagesStore.nextTemporaryUserMessage(emoji.text, false);
  }
}
