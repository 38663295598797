<label aria-hidden="true" for="chat-user-input" data-testid="user-input-label"
  ><i class="fof-icon fof-icon-primary fof-icon-chevron-right"></i
></label>
<input
  data-testid="user-input"
  type="text"
  rows="1"
  class="flex-grow-1 form-control rounded-pill border border-0"
  id="chat-user-input"
  autocomplete="off"
  placeholder="-"
  i18n-placeholder="@@chat.user-input.hint"
  aria-label="-"
  i18n-aria-label="@@chat.user-input.purpose"
  [requestFocus]="requestFocus()"
  [disabled]="isTextTypingDisabled()"
  [(ngModel)]="userText"
  (keydown.enter)="onSubmit($event)"
/>
<button
  data-testid="user-input-button"
  type="button"
  icon="send"
  (click)="onSubmit($event)"
  [disabled]="!userText"
  title="-"
  i18n-title="@@chat.user-input.send"
></button>
