import { BotImageIncomingMessage, BotIncomingMessage } from "../connector";
import { ConversationMessage, ImageMessage, MessageMetadata } from "../../common/message.model";
import { BotIncomingMessageConverter } from "./message-converter.model";

/** Converts a simple bot image message */
export class BotImageMessageConverter implements BotIncomingMessageConverter {
  supports(incomingMessage: BotIncomingMessage): boolean {
    return incomingMessage?.content_type === "image";
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const incomingImageMessage = incomingMessage as BotImageIncomingMessage;
    return [
      new ImageMessage(
        incomingImageMessage.content.url,
        incomingImageMessage.content.alt_text || "image",
        new MessageMetadata(incomingImageMessage.content.animation, "none", "default")
      ),
    ];
  }
}
