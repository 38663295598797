import { Inject, Injectable, Signal, signal } from "@angular/core";
import { APP_CONFIG, AppConfig } from "app/core";
import { Observable, Subject, concat, delayWhen, filter, of, switchMap, timer } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  BotTextMessage,
  ConversationMessage,
  CurrentConversationBlock,
  ImageMessage,
  UserTextMessage,
} from "../common/message.model";

/**
 * The store of messages of the current conversation block.
 * The store contains the messages retrieved from the backend server but also temporary messages.
 * When the user executes an action (new text message, click on suggestion button, ...),
 * the current block is adapted to quickly reflect the user action, and until the final
 * block is fetched from the backend server.
 * The chatbot messages are emitted after a delay to simulate a real user.
 */
@Injectable({ providedIn: "root" })
export class MessagesStore {
  private _blocks$ = new Subject<CurrentConversationBlock>();
  private _currentBlock = signal<CurrentConversationBlock | null>(null);
  private _message$ = new Subject<ConversationMessage>();
  private _messages = signal(new Array<ConversationMessage>());

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {
    this._blocks$
      .pipe(
        filter((block) => block !== null),
        // previous messages handling is cancelled, a new one is triggered
        switchMap((block) => this.dispatchMessages(block)),
        takeUntilDestroyed()
      )
      .subscribe(this._message$);

    this._message$
      .pipe(takeUntilDestroyed())
      .subscribe((message) => this._messages.update((value) => [...value, message]));
  }

  /** Dispatch the message from the passed block into the messages array and the messages stream */
  private dispatchMessages(block: CurrentConversationBlock): Observable<ConversationMessage> {
    if (block.temporary) {
      this._messages.set(block.messages.slice(0, block.messages.length - 1));
      return of(block.messages[block.messages.length - 1]);
    }
    return this.dispatchMessagesWithDelay(block);
  }

  /**
   * Dispatch the message from the passed block into the messages array and the messages
   * stream, with a delay except for the first user and bot messages, and the interaction
   * messages already introduced by a text message.
   */
  private dispatchMessagesWithDelay(
    block: CurrentConversationBlock
  ): Observable<ConversationMessage> {
    const { alreadyDisplayedMessages, immediateMessages, messagesToDelay } =
      this.partitionMessages(block);
    this._messages.set([...alreadyDisplayedMessages]);
    let cumulativeDelay = 0;
    return concat(
      of(...immediateMessages),
      of(...messagesToDelay).pipe(
        delayWhen((message) => {
          // no delay should be added between related messages
          if (message.metadata.relation !== "introduced") {
            cumulativeDelay += this.config.delayInConversationMessagesDisplayInMs;
          }
          return timer(cumulativeDelay);
        })
      )
    );
  }

  /**
   * Pushes a new temporary message resulting from an action of the user.
   * If the current block messages' display is still in progress (because of the delay),
   * all the text and image messages from this block are immediately displayed, before
   * displaying the user message. All interaction messages are ignored.
   */
  public nextTemporaryUserMessage(text: string, userWritten: boolean, color?: string): void {
    let newMessages = [];
    const currentBlock = this._currentBlock();
    if (currentBlock) {
      const previousMessages = currentBlock.messages.filter(
        (message) =>
          message instanceof UserTextMessage ||
          message instanceof BotTextMessage ||
          message instanceof ImageMessage
      );
      newMessages.push(...previousMessages);
    }
    newMessages.push(
      userWritten
        ? UserTextMessage.makeTextMessage(text)
        : UserTextMessage.makeInteractionResultMessage(text, color ?? "")
    );
    this._currentBlock.set(
      new CurrentConversationBlock("temporary-" + Date.now(), true, newMessages, null)
    );
    this._blocks$.next(this._currentBlock() as CurrentConversationBlock);
  }

  /** Push a new current block to the messages source. */
  public next(newBlock: CurrentConversationBlock): void {
    if (newBlock) {
      this._currentBlock.set(
        new CurrentConversationBlock(
          newBlock.id,
          newBlock.temporary,
          newBlock.messages,
          newBlock.evaluationQuestionnaire,
          newBlock.streaming
        )
      );
      this._blocks$.next(this._currentBlock() as CurrentConversationBlock);
    }
  }

  /** @return The current conversation block or null if none was already emitted */
  get currentBlock(): Signal<CurrentConversationBlock | null> {
    return this._currentBlock;
  }

  /** @return An observable to the current conversation blocks */
  get currentBlock$(): Observable<CurrentConversationBlock> {
    return this._blocks$.asObservable();
  }

  /** @return An observable to the messages retrieved from the current block */
  get message$(): Observable<ConversationMessage> {
    return this._message$.asObservable();
  }
  /**
   * @return The messages related to the current block.
   * An event is sent each time a new message is added to the array.
   * When the current conversation block is changed, an event is sent:
   * - For each new immediate message to be displayed
   * - For each other messages, sent with delay. The sent array contains all the
   *   previous messages of the current conversation block and the new message.
   */
  get messages(): Signal<Array<ConversationMessage>> {
    return this._messages;
  }

  /**
   * Split the messages of the passed block into three arrays:
   * 1/ The messages already displayed, and that can be displayed again, but
   *    should not trigger actions.
   * 2/ The messages to be displayed immediately on block reception.
   * 3/ The messages to be displayed with a delay between each of them.
   */
  private partitionMessages(block: CurrentConversationBlock) {
    if (block.messages.length > 0) {
      if (block.temporary) {
        const lastItemIndex = block.messages.length - 1;
        return {
          alreadyDisplayedMessages: block.messages.slice(0, lastItemIndex),
          immediateMessages: block.messages.slice(lastItemIndex),
          messagesToDelay: [],
        };
      }
      return this.partitionMessagesForNonTemporaryBlock(block);
    }
    return {
      alreadyDisplayedMessages: [],
      immediateMessages: [],
      messagesToDelay: [],
    };
  }

  private partitionMessagesForNonTemporaryBlock(block: CurrentConversationBlock) {
    let immediateMessages = [];
    let messagesToDelay = [];
    const firstIsUserMessage = block.messages[0] instanceof UserTextMessage;
    let endIndex = firstIsUserMessage ? 1 : 0;

    const hasBotMessage = endIndex < block.messages.length;
    if (hasBotMessage) {
      endIndex++;
      const hasRelatedBotMessage =
        endIndex < block.messages.length &&
        block.messages[endIndex].metadata.relation === "introduced";
      if (hasRelatedBotMessage) {
        endIndex++;
      }
    }
    immediateMessages = block.messages.slice(0, endIndex);
    messagesToDelay = block.messages.slice(immediateMessages.length);
    return { alreadyDisplayedMessages: [], immediateMessages, messagesToDelay };
  }
}
