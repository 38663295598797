import { Inject, Injectable } from "@angular/core";
import { IncomingConversationBlock, BotIncomingMessage, UserIncomingMessage } from "../connector";
import {
  BotTextMessage,
  ConversationMessage,
  CurrentConversationBlock,
  EvaluationQuestionnaire,
  ImageMessage,
  MessageMetadata,
} from "../../common/message.model";
import { INCOMING_BOT_MESSAGE_CONVERTERS, INCOMING_USER_MESSAGE_CONVERTERS } from "./di-tokens";
import {
  BotIncomingMessageConverter,
  UserIncomingMessageConverter,
} from "./message-converter.model";

/**
 * @return An evaluation questionnaire generated from the passed
 *         block, or undefined if no active questionnaire is found
 */
function toEvaluationQuestionnaire(
  block: IncomingConversationBlock
): EvaluationQuestionnaire | undefined {
  if (block.bot_message?.evaluation_answers?.is_evaluation_active !== true) {
    return undefined;
  }
  const data = block.bot_message.evaluation_answers;
  return {
    stepId: block.id,
    nodeName: block.bot_message.context.state_id,
    questionText: data.question_text,
    textOnPositiveEvaluation: data.text_after_positive_answer,
    textOnNegativeEvaluation: data.text_after_negative_answer,
    furtherEvaluationOptions: [
      { label: data.negative_evaluation_options.option1, key: "reason_1" },
      { label: data.negative_evaluation_options.option2, key: "reason_2" },
      { label: data.negative_evaluation_options.option3, key: "reason_3" },
    ],
  };
}

/**
 * Marks the position of text and image messages into a sequence.
 * A sequence is defined as a list of consecutive text or image messages.
 * A message introducing another one ends a sequence.
 */
function markPositionInSequence(messages: Array<ConversationMessage>): void {
  messages
    .reduce(
      (accumulator, current) => {
        if (current instanceof BotTextMessage || current instanceof ImageMessage) {
          current.metadata.position = "middle";
          accumulator[accumulator.length - 1].push(current);
        } else {
          accumulator.push([]);
        }
        return accumulator;
      },
      [new Array<BotTextMessage | ImageMessage>()]
    )
    .filter((sequence) => sequence.length > 0)
    .forEach((sequence) => {
      sequence[0].metadata.position = "start";

      if (!(sequence[sequence.length - 1] as any).streaming) {
        sequence[sequence.length - 1].metadata.position = "end";
      }
    });
}

/**
 * A converter of the messages from the backend server to business layer messages.
 * This converter is an abstraction layer that avoids leaking the backend server
 * messages model into the UI components and the business services.
 * It enriches the business layer messages metadata, to avoid complex computings in UI
 * components or business layer services.
 * If you need to support a new kind of messages, create a converter for it, and
 * reference it in the INCOMING_USER_MESSAGE_CONVERTERS array or in the INCOMING_BOT_MESSAGE_CONVERTERS.
 * Beware of the order of the converters!
 */
@Injectable({ providedIn: "root" })
export class IncomingConverter {
  /** Counter to compute the ID of each generated block. */
  private blocksCounter = 0;
  private lastStreamingMessage = "";
  private lastWasStreaming = false;

  constructor(
    @Inject(INCOMING_USER_MESSAGE_CONVERTERS)
    private incomingUserMessageConverters: Array<UserIncomingMessageConverter>,
    @Inject(INCOMING_BOT_MESSAGE_CONVERTERS)
    private incomingBotMessageConverters: Array<BotIncomingMessageConverter>
  ) {}

  private firstMatchingUserMessageConverter(incomingMessage: UserIncomingMessage) {
    for (let converter of this.incomingUserMessageConverters) {
      if (converter.supports(incomingMessage)) {
        return converter;
      }
    }
    return undefined;
  }
  private firstMatchingBotMessageConverter(incomingMessage: BotIncomingMessage) {
    for (let converter of this.incomingBotMessageConverters) {
      if (converter.supports(incomingMessage)) {
        return converter;
      }
    }
    return undefined;
  }

  private convertBotMessage(incomingMessage: BotIncomingMessage) {
    return this.firstMatchingBotMessageConverter(incomingMessage)?.convert(incomingMessage);
  }

  /**
   * @return The current conversation block corresponding to the passed backend block.
   *         All incoming messages are filtered or consolidated to simplify their usage
   *         in the application business layer.
   */
  toCurrentBlock(block: IncomingConversationBlock): CurrentConversationBlock {
    const userMessage = this.firstMatchingUserMessageConverter(block.user_message)?.convert(
      block.user_message
    );

    let botMessages: Array<ConversationMessage> = block.bot_message.answers
      .flatMap((incomingMessage) => this.convertBotMessage(incomingMessage))
      .filter((message) => message !== undefined)
      .map((message) => message as ConversationMessage);

    const streamingMessages = botMessages.filter(
      (message) => message instanceof BotTextMessage && message.streaming
    );
    let temporary = false;

    const streamedMessages = botMessages.filter(
      (message) => message instanceof BotTextMessage && message.streamed
    );

    if (streamingMessages.length > 0) {
      temporary = true;
      const streamingMessageContent = (streamingMessages[0] as BotTextMessage).text;

      if (
        streamingMessageContent.length === 0 ||
        streamingMessageContent.length < this.lastStreamingMessage.length
      ) {
        botMessages = new Array<ConversationMessage>(); // empty so ignored
      } else {
        this.lastStreamingMessage = streamingMessageContent;
      }
    } else {
      this.lastStreamingMessage = "";
    }

    if (this.lastWasStreaming || temporary || streamedMessages.length > 0) {
      botMessages = botMessages.flatMap((message: ConversationMessage) => {
        if (message instanceof BotTextMessage) {
          const trimmed = message.text.trim();
          const lastIsSentence = trimmed.endsWith(".");
          const splitMessages = [
            ...message.text
              .trim()
              .split(".")
              .filter((text) => text.length > 0)
              .map((text) =>
                BotTextMessage.makeMessage(text + ".", new MessageMetadata("", "none", "default"))
              ),
          ];
          // if (!lastIsSentence && !temporary) {
          //   console.log("IGNORED:", splitMessages[splitMessages.length - 1].text);
          // }

          return lastIsSentence ? splitMessages : splitMessages.slice(0, splitMessages.length - 1);
        }
        return [message as ConversationMessage];
      });
    }

    this.lastWasStreaming = temporary;

    markPositionInSequence(botMessages);
    this.blocksCounter++;
    return new CurrentConversationBlock(
      this.blocksCounter + "",
      temporary,
      userMessage ? [userMessage, ...botMessages] : botMessages,
      null,
      temporary
    );
  }
}
