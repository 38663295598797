import { Inject, Pipe, PipeTransform } from "@angular/core";
import { APP_CONFIG, AppConfig } from "../config";

/** A pipe used to transform unicode characters into HTML icons */
@Pipe({ name: "iconize", standalone: true })
export class IconizePipe implements PipeTransform {
  private thumbsUpLabel = $localize`:@@icon.thumbs-up:`;
  private thumbsDownLabel = $localize`:@@icon.thumbs-down:`;
  private distLocation = "";

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {
    this.distLocation = config.distLocation;
  }

  transform(value: string, ..._args: unknown[]): string {
    let result = value;
    if (result) {
      result = result
        .replace(
          /👍/g,
          `<i aria-label="${this.thumbsUpLabel}" class="fof-icon fof-icon-thumbs-up"></i>`
        )
        .replace(
          /👎/g,
          `<i aria-label="${this.thumbsDownLabel}" class="fof-icon fof-icon-thumbs-down"></i>`
        );
      if (this.config.convertEmojisToImages) {
        result = result
          .replace(
            /(😊)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f600.png">`
          )
          .replace(
            /(😉)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f609.png">`
          )
          .replace(
            /(😁)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f601.png">`
          )
          .replace(
            /(😂)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f602.png">`
          )
          .replace(
            /(😃)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f603.png">`
          )
          .replace(
            /(😄)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f604.png">`
          )
          .replace(
            /(😆)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f606.png">`
          )
          .replace(
            /(😰)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f630.png">`
          )
          .replace(
            /(😲)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f632.png">`
          )
          .replace(
            /(😓)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f630.png">`
          )
          .replace(
            /(😭)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f630.png">`
          )
          .replace(
            /(🙂)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f600.png">`
          ) //1f642
          .replace(
            /(😮)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f632.png">`
          ) //1f62e
          .replace(
            /(😯)/g,
            `<img alt="$1" src="${this.distLocation}/assets/specific/pop/emoji/1f632.png">`
          ); //1f62f
      }
    }
    return result;
  }
}
