import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { MessagesScrollRequester } from "./messages-scroll.requester";
import { ScrollRequest } from "app/core/ui";

/** Special scroll directive particularly necessary for image (we need to wait full loading before asking for scroll request) */
@Directive({
  selector: "img[scrollOnLoad]",
  standalone: true,
})
export class ScrollOnLoadDirective {
  @Input() scrollOnLoad!: string;

  constructor(
    private messageScrollRequester: MessagesScrollRequester,
    el: ElementRef<HTMLInputElement>
  ) {}

  @HostListener("load") load() {
    this.messageScrollRequester.next(new ScrollRequest(this.scrollOnLoad, "start"));
  }
}
