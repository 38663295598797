import { ChangeDetectionStrategy, Component, HostBinding, Signal } from "@angular/core";
import { ScrollRequest, AutoScrollDirective } from "app/core/ui";
import { CurrentConversationBlockComponent, UserInputComponent } from "../../current";
import { HistoryComponent } from "../../history";
import { MessagesScrollRequester } from "../../common/messages-scroll.requester";

/**
 * Displays the current conversation block and past messages.
 * The accessibility features for the conversation are handled by this component:
 * - On the reception of a new block, the screen reader is requested to read the messages.
 * - History messages are never read by the screen reader.
 * - If the block ends with an interaction message, the block is immediately visible to the screen reader.
 * - If the block ends with a non interaction message, the block is not immediately visible to the screen reader,
 *   the user input is focused (with a delay), and then the block is made visible to the screen reader. Hence,
 *   on such blocks, the user input is first described by the screen reader, and then, the current block is read.
 */
@Component({
  selector: "fof-messages-box",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HistoryComponent,
    CurrentConversationBlockComponent,
    UserInputComponent,
    AutoScrollDirective,
  ],
})
export class MessagesBoxComponent {
  @HostBinding("class") hostClasses = "h-100 d-flex flex-column";

  protected readonly currentScrollRequests: Signal<ScrollRequest>;

  constructor(messagesScrollRequester: MessagesScrollRequester) {
    this.currentScrollRequests = messagesScrollRequester.requests;
  }
}
