<button
  data-testid="button"
  [requestFocus]="needsFocus"
  large
  class="d-block"
  (click)="selected.emit()"
>
  {{ text }}
</button>
<small
  class="mt-2 mb-0"
  style="font-size: var(--fof-large-button-label-font-size)"
  data-testid="extraLabel"
>
  {{ label }}
</small>
