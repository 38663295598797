import { InjectionToken } from "@angular/core";
import { LockUtil } from "app/core";

/**
 * A service to temporarily lock the conversation from a user perspective:
 * while the conversation is locked, the user cannot send messages.
 */
export const CONVERSATION_LOCK = new InjectionToken<LockUtil>("CONVERSATION_LOCK", {
  factory: () => new LockUtil(true),
});
