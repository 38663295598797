<hr style="border-color: var(--fof-border-color)" class="mb-4 opacity-100" />
<p i18n="@@satisfaction.level.question">-</p>
<div class="d-flex justify-content-evenly">
  <ng-container *ngFor="let suggestion of message.suggestions; index as level; first as isFirst">
    <fof-labeled-large-button
      [attr.data-testid]="'button_' + level"
      [needsFocus]="isFirst"
      [text]="level + 1 + ''"
      [label]="satisfactionLevelLabels[level]"
      (selected)="onSelect(suggestion, satisfactionLevelResultLabels[level])"
    />
  </ng-container>
</div>
