import { APP_INITIALIZER } from "@angular/core";
import { APP_CONFIG, AppConfig } from "../config";
// import { registerLocaleData } from "@angular/common";
import { loadTranslations } from "@angular/localize";
import "@angular/common/locales/global/fr";
import * as fr from "../../../assets/i18n/fr.json";

function configureTranslations(config: AppConfig) {
  return async () => {
    // Use web pack magic string to only include required locale data
    // TODO : add dynamic import when refactoring loader
    // const localeModule = await import(`/node_modules/@angular/common/locales/${config.lang}.mjs`);

    // registerLocaleData(localeModule.default);

    // const rawTranslations = await import(`/src/assets/i18n/${config.lang}.json`);
    const rawTranslations = fr;
    const translations = {
      ...rawTranslations.common,
      ...(rawTranslations[config.botName] ?? {}),
    };
    loadTranslations(translations);
  };
}

export function provideI18n() {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: configureTranslations,
      multi: true,
      deps: [APP_CONFIG],
    },
  ];
}
