import { Injectable } from "@angular/core";
import { ChatOpenStore } from "./open";
import { ConversationService } from "./incoming";
import { HistoryStore } from "./history";

/** A service used to execute actions related to the lifecycle of the chatbot */
@Injectable({ providedIn: "root" })
export class ChatLifecycleService {
  private _switchedOff = false;

  constructor(
    private chatOpenStore: ChatOpenStore,
    private conversationService: ConversationService,
    private historyStore: HistoryStore
  ) {}

  /** Initializes the conversation */
  initialize(): void {
    this.historyStore.clear();
    this.conversationService.initialize();
  }

  /** Clear the current conversation and relaunching a new one */
  reset(): void {
    this.conversationService.reset();
    this.historyStore.clear();
  }

  /** Close the chatbot and remove any messages */
  shutdown(): void {
    this.conversationService.clear();
    this.historyStore.clear();
    this.chatOpenStore.close();
  }

  /** @return Whether the chat bot was switched off to display Dimelo */
  get switchedOff(): boolean {
    return this._switchedOff;
  }

  /** Notifies that the chat bot was switched off to display the external chat */
  notifySwitchedOff() {
    this._switchedOff = true;
    this.conversationService.clear();
    this.historyStore.clear();
  }
}
