import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { MaintenanceStatus } from "./maintenance-status.model";

/** Retrieve the current maintenance status of the backend application */
export class MaintenanceApiClient {
  constructor(
    private endPoint: string,
    private http: HttpClient
  ) {}

  /** @return The maintenance status of the backend application */
  public retrieveStatus(): Observable<MaintenanceStatus> {
    return this.http.get<MaintenanceStatus>(this.endPoint);
  }
}
