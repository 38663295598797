import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SatisfactionCommentMessage } from "app/chat/common/message.model";
import { OutgoingMessageService } from "app/chat/outgoing";
import { MessagesStore } from "../../messages.store";
import { NgFor } from "@angular/common";
import { RequestFocusDirective } from "app/core/ui";
import { FormsModule } from "@angular/forms";
import { FormButtonComponent } from "app/core/ui/form-button-component";

/** The component enabling a user to evaluate his satisfaction through a free comment. */
@Component({
  selector: "fof-satisfaction-comment",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, FormsModule, RequestFocusDirective, FormButtonComponent],
})
export class SatisfactionCommentComponent {
  private messageForCancelledFreeComment = $localize`:@@satisfaction.comment.message-for-cancel:`;

  @Input() message!: SatisfactionCommentMessage;

  /** The comment filled in by the user */
  protected userText = "";

  constructor(
    private outgoingMessageService: OutgoingMessageService,
    private messagesStore: MessagesStore
  ) {}

  /** Notifies that the user refuses to submit a comment */
  onCancel(event: Event) {
    event.preventDefault();
    this.outgoingMessageService.sendSatisfactionCommentMessage(
      this.messageForCancelledFreeComment,
      this.message.intention
    );
    this.messagesStore.nextTemporaryUserMessage(this.messageForCancelledFreeComment, true);
  }

  /** Sends the user comment */
  onSubmit(event: Event): void {
    event.preventDefault();
    const text = this.userText.trim();
    if (text) {
      this.outgoingMessageService.sendSatisfactionCommentMessage(text, this.message.intention);
      this.messagesStore.nextTemporaryUserMessage(text, true);
    }
  }
}
