import { BotIncomingMessage, BotTextIncomingMessage } from "../connector";
import { BotTextMessage, ConversationMessage, MessageMetadata } from "../../common/message.model";
import { BotIncomingMessageConverter } from "./message-converter.model";

/**
 * Converts a message used to suggest answers to the user.
 */
export class BotTextMessageConverter implements BotIncomingMessageConverter {
  supports(incomingMessage: BotIncomingMessage): boolean {
    return (
      incomingMessage?.content_type === "text" && incomingMessage.content?.hasOwnProperty("value")
    );
  }
  convert(incomingMessage: BotIncomingMessage): Array<ConversationMessage> {
    const botTextMessage = incomingMessage as BotTextIncomingMessage;
    let message: BotTextMessage;
    if (botTextMessage.content.streamed) {
      message = BotTextMessage.makeStreamedMessage(
        botTextMessage.content.value,
        new MessageMetadata(botTextMessage.content.animation, "none", "default")
      );
    } else {
      message =
        botTextMessage.content.streaming === true
          ? BotTextMessage.makeStreamingMessage(
              botTextMessage.content.value,
              new MessageMetadata(botTextMessage.content.animation, "none", "default")
            )
          : BotTextMessage.makeMessage(
              botTextMessage.content.value,
              new MessageMetadata(botTextMessage.content.animation, "none", "default")
            );
    }
    return [message];
  }
}
