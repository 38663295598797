/** Dependency injection tokens */

import { InjectionToken } from "@angular/core";
import { SwitcherExtraInformation } from "./external-switcher.model";
import { Subject } from "rxjs";

// Token for the external chat switcher service
export const EXTERNAL_SWITCHER = new InjectionToken<Subject<SwitcherExtraInformation>>(
  "EXTERNAL_SWITCHER",
  { factory: () => new Subject<SwitcherExtraInformation>() }
);
