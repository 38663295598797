import { Inject, Injectable, Signal, computed } from "@angular/core";
import { AppHostService, LockUtil } from "app/core";
import { filter, map, merge } from "rxjs";
import { toSignal } from "@angular/core/rxjs-interop";
import { CONVERSATION_LOCK } from "../../di-tokens";
import { UserRightsStore } from "app/user";
import { MessagesStore } from "../messages.store";
import { ChatBoxContentSwitcher } from "app/chat/common/chat-box-content.switcher";

/** The store of the states involved in the user text input */
@Injectable()
export class UserInputStateStore {
  /**
   * @return An signal of events to force the user input to gain focus. Values are the current
   * timestamp, as an ID for the request.
   */
  public readonly requestFocus: Signal<number | null>;

  /** @return Whether the user is not allowed to enter a new text message */
  public readonly isTextTypingDisabled: Signal<boolean>;

  constructor(
    @Inject(CONVERSATION_LOCK) conversationLock: LockUtil,
    userRightsStore: UserRightsStore,
    contentSwitcher: ChatBoxContentSwitcher,
    messageStore: MessagesStore,
    appHostService: AppHostService
  ) {
    this.isTextTypingDisabled = computed(
      () =>
        conversationLock.locked() ||
        !userRightsStore.canWriteSignal() ||
        !contentSwitcher.areMessagesDisplayed()
    );

    this.requestFocus = toSignal(
      merge(
        messageStore.currentBlock$.pipe(
          filter((block) => block.expectsUserText()),
          map(() => Date.now()) // current timestamp used as the focus request ID
        ),
        appHostService.focusOnUserInput$
      ),
      {
        initialValue: messageStore.currentBlock()?.expectsUserText() ? Date.now() : 0,
      }
    );
  }
}
