import { Inject, Injectable } from "@angular/core";
import { MessagesStore } from "app/chat/current";
import { APP_CONFIG, AppConfig } from "app/core";
import {
  concat,
  concatMap,
  debounceTime,
  filter,
  ignoreElements,
  map,
  Observable,
  of,
  timer,
} from "rxjs";
import { ANIMATIONS_DEBOUNCE_DELAY } from "./di-tokens";

export type AnimationRequest = { type: "path" | "url"; value: string };

/** Factory to create a queue to notify animations */
@Injectable()
export class AnimationQueueFactory {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(ANIMATIONS_DEBOUNCE_DELAY) private animationsDebounceDelay: number,
    private messageStore: MessagesStore
  ) {}

  /**
   * Builds a new stream that contains a single animation request and that is preceded by the passed duration and lasts
   * for the passed duration.
   * No other animation can be executed while the animation is not fully executed.
   * Hence, it is used to simulate a queue.
   * @param durationBefore The duration before executing the animation
   * @param durationAfter The duration of the animation
   */
  private makeRequestWithDuration(
    durationBefore: number,
    request: AnimationRequest,
    durationAfter: number
  ) {
    return concat(
      timer(durationBefore).pipe(ignoreElements()),
      of(request),
      timer(durationAfter).pipe(ignoreElements())
    );
  }

  /** Creates a new queue that notifies requests for animations playback. Must be unsubscribed. */
  makeQueue(): Observable<AnimationRequest> {
    let triggerFirstAfter = 0;
    let defaultAnimationStream: Observable<AnimationRequest> = of();

    if (this.config.headerLogoAnimations?.default) {
      triggerFirstAfter = this.config.headerLogoAnimations.default.duration;
      defaultAnimationStream = of({
        type: "path",
        value: this.config.headerLogoAnimations.default.path,
      });
    }
    const animationUrlsStream = this.messageStore.message$.pipe(
      map((message) => message.metadata.animationUrl),
      filter((url) => !!url),
      map((url) => url as string),
      // avoid playing a succession of animations when receiving multiple conversation blocks in a short period
      debounceTime(this.animationsDebounceDelay),
      concatMap((url, index) =>
        this.makeRequestWithDuration(
          // if the default animation was executed, we need to delay only the first animation received
          index === 0 ? triggerFirstAfter : 0,
          { type: "url", value: url },
          this.config.headerLogoAnimations?.duration || 4000
        )
      )
    );
    return concat(defaultAnimationStream, animationUrlsStream);
  }
}
