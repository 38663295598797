import { UserIncomingMessage, InteractionResultIncomingMessage } from "../connector";
import { ConversationMessage, UserTextMessage } from "../../common/message.model";
import {
  SupportedSatisfactionLevelResultType,
  UserIncomingMessageConverter,
} from "./message-converter.model";

/** Converter for a message that is the result of an interaction message */
export class InteractionResultMessageConverter implements UserIncomingMessageConverter {
  constructor(
    private supportedSatisfactionLevels: () => readonly SupportedSatisfactionLevelResultType[]
  ) {}

  supports(incomingMessage: UserIncomingMessage): boolean {
    return (
      incomingMessage?.content_type === "postback" &&
      incomingMessage.content?.hasOwnProperty("text") &&
      !!(incomingMessage as InteractionResultIncomingMessage).content.text
    );
  }
  convert(incomingMessage: UserIncomingMessage): ConversationMessage {
    const interactionMessage = incomingMessage as InteractionResultIncomingMessage;
    return UserTextMessage.makeInteractionResultMessage(
      this.adaptSatisfactionLevelText(interactionMessage),
      interactionMessage.content.color ?? ""
    );
  }

  /** Adapts the text of a satisfaction evaluation result so that it is consistency with
   * the modern satisfaction level evaluation */
  private adaptSatisfactionLevelText(message: InteractionResultIncomingMessage): string {
    let text = message.content.text;
    this.supportedSatisfactionLevels()
      .filter(
        (possibleResult) =>
          possibleResult.intent === message.content.intent &&
          possibleResult.text === message.content.text
      )
      .slice(0, 1)
      .forEach(({ label }) => (text = label));
    return text;
  }
}
