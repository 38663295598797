import { UserIncomingMessage, InteractionResultIncomingMessage } from "../connector";
import { ConversationMessage, UserTextMessage } from "../../common/message.model";
import { UserIncomingMessageConverter } from "./message-converter.model";

/** Converter for a message corresponding to a free comment written by a user related to his satisfaction. */
export class UserSatisfactionCommentMessageConverter implements UserIncomingMessageConverter {
  supports(incomingMessage: UserIncomingMessage): boolean {
    return (
      incomingMessage?.content_type === "postback" &&
      incomingMessage.content?.hasOwnProperty("text") &&
      !!(incomingMessage as InteractionResultIncomingMessage).content.text &&
      incomingMessage.content?.hasOwnProperty("intent") &&
      (incomingMessage as InteractionResultIncomingMessage).content.intent === "free_comment_reply"
    );
  }
  convert(incomingMessage: UserIncomingMessage): ConversationMessage {
    const interactionMessage = incomingMessage as InteractionResultIncomingMessage;
    return UserTextMessage.makeTextMessage(interactionMessage.content.text);
  }
}
