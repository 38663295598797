import { Injectable } from "@angular/core";

/** Handles the switch to Dimelo */
@Injectable({ providedIn: "root" })
export class DimeloStateStore {
  /** @return Whether the closing of the chat bot is blocked for the user */
  get isDimeloChatDisplayed(): boolean {
    return document.getElementsByClassName("dimelo-chat-window").length > 0;
  }
}
