import { ChangeDetectionStrategy, Component, HostBinding, Signal } from "@angular/core";
import { PersonalInfoComponent } from "../../switch/personal-info";
import { MessagesBoxComponent } from "../messages-box-component";
import { NgIf } from "@angular/common";
import {
  ChatBoxContentId,
  ChatBoxContentSwitcher,
} from "app/chat/common/chat-box-content.switcher";

/** A component displaying the messages available in the lastly received conversation block. */
@Component({
  selector: "[chatBox]",
  templateUrl: "./template.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MessagesBoxComponent, PersonalInfoComponent],
})
export class ChatBoxComponent {
  @HostBinding("class") hostClasses = "rounded-bottom-5";
  @HostBinding("style") styleClasses =
    "border-width: 0 var(--fof-main-border-width) var(--fof-main-border-width) var(--fof-main-border-width); border-style: solid; border-color: var(--fof-border-color);";

  protected readonly contentId: Signal<ChatBoxContentId>;

  constructor(contentSwitcher: ChatBoxContentSwitcher) {
    this.contentId = contentSwitcher.id;
  }
}
