import { Pipe, PipeTransform } from "@angular/core";

/** A pipe to remove illegal HTML characters or tags in a bot or user message */
@Pipe({
  name: "cleanhtml",
  standalone: true,
})
export class CleanHtmlPipe implements PipeTransform {
  transform(value: string, ..._args: unknown[]): string {
    let result = value;
    if (result) {
      // remove paragraphs
      result = result.replace(/^<p>/i, "").replace(/<\/p>$/i, "");
      // remove faulty nbsp
      result = result.replace(/&amp;nbsp/g, " ");
    }
    return result;
  }
}
