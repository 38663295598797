import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { LargeButtonDirective } from "../large-button-directive";
import { RequestFocusDirective } from "../request-focus.directive";

/** A large button containing a single character, with an extra label to describe it */
@Component({
  selector: "fof-labeled-large-button",
  templateUrl: "./template.html",
  styleUrls: ["./style.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LargeButtonDirective, RequestFocusDirective],
})
export class LabeledLargeButtonComponent {
  @HostBinding("class") hostClasses = "d-flex flex-column align-items-center";

  /** Text for the button */
  @Input()
  public text = "";

  /** Extra label for the button */
  @Input()
  public label = "";

  /** Whether to focus the button on display */
  @Input()
  public needsFocus = false;

  /** Notifier for clicks on the button */
  @Output()
  public selected = new EventEmitter<void>();
}
