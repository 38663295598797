import { Inject, Injectable } from "@angular/core";
import { ConversationIdStore } from "./conversation-id.store";
import { MessagesFetcher } from "./messages.fetcher";
import { MESSAGES_FETCHER } from "./di-tokens";

/** Service used to handle conversations. */
@Injectable({ providedIn: "root" })
export class ConversationService {
  constructor(
    private conversationIdStore: ConversationIdStore,
    @Inject(MESSAGES_FETCHER) private messagesFetcher: MessagesFetcher
  ) {}

  /** Activates the effective retrieval of conversation messages. */
  public initialize(): void {
    this.messagesFetcher.initialize();
  }

  /**
   * Notifies that new incoming messages are expected to be received.
   * Creates a new conversation or relaunch a previous one, and starts retrieving
   * conversation messages from the server, only if the retrieval is activated.
   */
  public awaitIncomingMessages(): void {
    this.messagesFetcher.awaitIncomingMessages();
  }

  /**
   * Deletes data about the current conversation, no new conversation is created.
   * Stop the current subscriptions to the conversation messages.
   */
  public clear() {
    this.conversationIdStore.invalidate();
    this.messagesFetcher.stop();
  }

  /**
   * Resets the current conversation, creating a new one.
   * Does not invalidate the current subscriptions to the conversation messages.
   */
  public reset() {
    this.conversationIdStore.invalidate();
    this.messagesFetcher.awaitIncomingMessages();
  }
}
