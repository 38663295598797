import { Component, Inject, Input } from "@angular/core";
import { SatisfactionLevelMessage, Suggestion } from "app/chat/common/message.model";
import { NgFor, NgIf } from "@angular/common";
import { OutgoingMessageService } from "app/chat/outgoing";
import { MessagesStore } from "../../messages.store";
import { LabeledLargeButtonComponent } from "app/core/ui/labeled-large-button-component";
import {
  SATISFACTION_LEVEL_LABELS,
  SATISFACTION_LEVEL_RESULTS_LABELS,
} from "app/chat/incoming/converter/di-tokens";

/**
 * Displays a list of graphical elements the user can interact
 * with to evaluate his satisfaction regarding the conversation.
 */
@Component({
  selector: "fof-satisfaction-level",
  templateUrl: "./template.html",
  standalone: true,
  imports: [NgFor, NgIf, LabeledLargeButtonComponent],
})
export class SatisfactionLevelComponent {
  @Input() message!: SatisfactionLevelMessage;

  constructor(
    private outgoingMessageService: OutgoingMessageService,
    private messagesStore: MessagesStore,
    @Inject(SATISFACTION_LEVEL_LABELS) protected satisfactionLevelLabels: readonly string[],
    @Inject(SATISFACTION_LEVEL_RESULTS_LABELS)
    protected satisfactionLevelResultLabels: readonly string[]
  ) {}

  /**
   * Executes the expected behavior for the passed suggestion
   * @param resultLabel The temporary label to be displayed when selecting a level
   */
  onSelect(suggestion: Suggestion, resultLabel: string): void {
    this.outgoingMessageService.sendAcceptedSuggestionMessage(suggestion);
    this.messagesStore.nextTemporaryUserMessage(resultLabel, false);
  }
}
