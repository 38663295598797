import { Injectable } from "@angular/core";
import { ChatSwitchService } from "./switch.service";
import { ChatSwitchSyncService } from "./switch-sync.service";
import { PersonalInfo, PersonalInfoStore } from "./personal-info";

/** Implementation of the switch to an external chat for which personal information is required
 * from the user. */
@Injectable()
export class ChatSwitchWithPersonalInfoService implements ChatSwitchService {
  constructor(
    private personalInfoStore: PersonalInfoStore,
    private switchSyncService: ChatSwitchSyncService
  ) {
    // we always listen to events of personal information availability
    this.personalInfoStore
      .get()
      .subscribe((personalInfo: PersonalInfo) =>
        this.switchSyncService.switchChatOff({ personalInfo })
      );
  }

  /** Switches from the chatbot to external chat only if personal information is provided by the user. */
  switchChatOff(): void {
    this.personalInfoStore.request();
  }
}
