import { InjectionToken, inject } from "@angular/core";
import { APP_CONFIG, CountDown, LocalStorageStore, PersistentStore } from "app/core";
import { ConversationId } from "./conversation-id.model";
import { MessagesFetcher } from "./messages.fetcher";
import { MessagesPollingFetcher } from "./messages.polling.fetcher";
import { ConversationApiClient } from "./conversation.apiclient";
import { ConversationUtil } from "./conversation.util";
import { ConversationIdStore } from "./conversation-id.store";

export const CONVERSATION_ID_PERSISTENT_STORE = new InjectionToken<PersistentStore<ConversationId>>(
  "CONVERSATION_ID_PERSISTENT_STORE",
  {
    factory: () => {
      const appConfig = inject(APP_CONFIG);
      return new LocalStorageStore<ConversationId>(
        window.localStorage,
        appConfig.localStoragePrefix + appConfig.botVersion + "_conversation",
        ["id", "token"],
        3600000
      );
    },
  }
);
export const MESSAGES_FETCHER = new InjectionToken<MessagesFetcher>("MESSAGES_FETCHER", {
  factory: () =>
    // polls for 60 seconds, each second
    new MessagesPollingFetcher(
      500,
      new CountDown(120),
      inject(ConversationApiClient),
      inject(ConversationIdStore),
      new ConversationUtil()
    ),
});
