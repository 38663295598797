import { Injectable } from "@angular/core";
import { AnimationRequest, AnimationApiClient } from "app/animation";
import { AnimationConfigWithData } from "lottie-web";
import { AnimationOptions } from "ngx-lottie";
import { map, Observable, of } from "rxjs";

/** Factory to create animation options from animation requests */
@Injectable()
export class AnimationOptionsFactory {
  constructor(private animationApiClient: AnimationApiClient<AnimationConfigWithData>) {}

  /** Builds new animation options from the passed request */
  makeOptions(request: AnimationRequest): Observable<AnimationOptions> {
    return request.type === "url"
      ? this.requestAnimationDataByUrl(request.value)
      : of({ autoplay: true, loop: false, path: request.value });
  }

  /** Requests animation data, ignoring errors */
  private requestAnimationDataByUrl(url: string): Observable<AnimationOptions> {
    return this.animationApiClient
      .get(url)
      .pipe(map((animationData) => ({ autoplay: true, loop: false, animationData })));
  }
}
