import { Inject, Injectable } from "@angular/core";
import { Subject, delay } from "rxjs";
import { SwitcherExtraInformation } from "app/common";
import { ChatSwitchService } from "./switch.service";
import { ChatSwitchSyncService } from "./switch-sync.service";
import { CHAT_SWITCH_DELAY } from "./di-tokens";

/** Implementation of a delayed switch to an external chat. */
@Injectable({ providedIn: "root" })
export class ChatSwitchDelayedService implements ChatSwitchService {
  private delayedSwitches$ = new Subject<SwitcherExtraInformation>();

  constructor(
    private switchSyncService: ChatSwitchSyncService,
    @Inject(CHAT_SWITCH_DELAY) delayInMs: number
  ) {
    this.delayedSwitches$
      .pipe(delay(delayInMs))
      .subscribe((extraInfo: SwitcherExtraInformation) =>
        this.switchSyncService.switchChatOff(extraInfo)
      );
  }

  switchChatOff(extraInfo: SwitcherExtraInformation = {}): void {
    this.delayedSwitches$.next(extraInfo);
  }
}
