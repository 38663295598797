<p class="px-4 pt-3 mb-2" i18n="@@personal-info.form.legend">-</p>

<form
  class="d-flex flex-column px-4 pb-3"
  aria-label="-"
  i18n-aria-label="@@personal-info.form.label"
  [formGroup]="personalInfoForm"
  (ngSubmit)="onSubmit()"
>
  <label for="name" class="mb-1 text-muted" i18n="@@field.name.label">-</label>
  <mat-form-field appearance="fill">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.name.hint"
      formControlName="name"
      id="name"
    />
  </mat-form-field>

  <label for="firstname" class="mb-1 text-muted" i18n="@@field.firstname.label">-</label>
  <mat-form-field appearance="fill">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.firstname.hint"
      formControlName="firstname"
      id="firstname"
    />
  </mat-form-field>

  <label for="staff-number" class="mb-1 text-muted" i18n="@@field.staff-number.label">-</label>
  <mat-form-field appearance="fill">
    <div class="d-flex">
      <input
        matInput
        placeholder="-"
        i18n-placeholder="@@field.staff-number.hint"
        formControlName="id"
        id="staff-number"
      />
      <div
        data-toggle="tooltip"
        data-placement="top"
        title="-"
        i18n-title="@@field.staff-number.help"
      >
        &#9432;
      </div>
    </div>
  </mat-form-field>

  <label for="employer" class="mb-1 text-muted" i18n="@@field.employer.label">-</label>
  <mat-form-field appearance="fill">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.employer.hint"
      formControlName="employer"
      id="employer"
    />
  </mat-form-field>

  <label for="email" class="mb-1 text-muted" i18n="@@field.email.label">-</label>
  <mat-form-field appearance="fill">
    <input
      matInput
      placeholder="-"
      i18n-placeholder="@@field.email.hint"
      formControlName="email"
      id="email"
    />
    <!-- prettier-ignore -->
    <mat-error role="alert" *ngIf="personalInfoForm.controls.email.invalid" i18n="@@field.email.error">-</mat-error>
  </mat-form-field>

  <button formButton="primary" role="submit" type="submit" i18n="@@button.send">-</button>

  <button
    formButton="primary-text"
    role="cancel"
    type="reset"
    (click)="onCancel()"
    i18n="@@button.close"
  >
    -
  </button>
</form>
