import { Injectable } from "@angular/core";
import { WindowFacade } from "./window-facade";

/** A collection of utilities related to device type */
@Injectable({ providedIn: "root" })
export class DeviceUtil {
  constructor(private wind: WindowFacade) {}

  /** @return Whether application is being executed on touch screen (MDN extract) */
  get hasTouchScreen(): boolean {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in this.wind.navigator) {
      hasTouchScreen = this.wind.navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in this.wind.navigator) {
      hasTouchScreen = this.wind.navigator["msMaxTouchPoints"] > 0;
    } else {
      const mQ = this.wind.matchMedia?.("(pointer:coarse)");
      if (mQ?.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in this.wind) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = this.wind.navigator["userAgent"];
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    return hasTouchScreen;
  }

  /** @return Whether the application is being executed on a device with Mobi in User Agent */
  get hasMobileUserAgent(): boolean {
    const UA = this.wind.navigator["userAgent"];
    return /Mobi/.test(UA);
  }

  /** @return Whether the application is being executed on mobile */
  get isMobile(): boolean {
    return this.hasMobileUserAgent && this.hasTouchScreen;
  }
}
