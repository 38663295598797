import { APP_INITIALIZER } from "@angular/core";
import { loadScript, APP_CONFIG, AppConfig } from "app/core";
import { EXTERNAL_SWITCHER, SwitcherExtraInformation } from "app/common";
import { DimeloSwitcher } from "./dimelo.switcher";
import { Observable } from "rxjs";

declare let Dimelo: any;
export { DimeloStateStore } from "./dimelo-state.store";

function loadDimelo(config: AppConfig): () => Promise<unknown> | undefined {
  return () => {
    if (
      config.dimeloLoaderUrl &&
      (typeof Dimelo === "undefined" || typeof Dimelo.Chat === "undefined")
    ) {
      return loadScript(config.dimeloLoaderUrl);
    }
    return undefined;
  };
}

/** Initializes the observer to the switch events. */
export function setupSwitchObservers(
  externalSwitcher: Observable<SwitcherExtraInformation>,
  dimeloSwitcher: DimeloSwitcher
) {
  return () => {
    externalSwitcher.subscribe((info) => dimeloSwitcher.switch(info));
  };
}

export function provideDimelo() {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: loadDimelo,
      multi: true,
      deps: [APP_CONFIG],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupSwitchObservers,
      multi: true,
      deps: [EXTERNAL_SWITCHER, DimeloSwitcher],
    },
  ];
}
