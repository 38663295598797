import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
} from "@angular/core";
import { Suggestion } from "app/chat/common/message.model";
import { APP_CONFIG, AppConfig, CleanHtmlPipe, IconizePipe } from "app/core";
import { SuggestionExecutor } from "../suggestion.executor";
import { NgStyle } from "@angular/common";

/** A button to be displayed in the conversation box, allowing the user
 * to select an action to be executed */
@Component({
  selector: "button[suggestion]",
  templateUrl: "./template.html",
  styleUrls: ["./style.scss"],
  standalone: true,
  imports: [NgStyle, CleanHtmlPipe, IconizePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionComponent {
  private _suggestion!: Suggestion;
  protected text: string = "";

  @HostBinding("class") hostClasses =
    "d-inline-block py-2 px-3 fs-6 lh-sm text-break text-center btn btn-outline-light rounded-pill";
  @HostBinding("style") hostStyles: { [klass: string]: any } = {};

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private suggestionExecutor: SuggestionExecutor
  ) {}

  @Input()
  set suggestion(newValue: Suggestion) {
    this._suggestion = newValue;
    this.text = this._suggestion.text;
    this.computeStyle();
  }

  @HostListener("click") onClick() {
    return this.onAction();
  }

  /** Executes the expected behaviour for the suggestion related to this component */
  private onAction(): boolean {
    this.suggestionExecutor.execute(this._suggestion);
    return false;
  }

  private computeStyle() {
    if (this.config.hasCustomButtonColor && this._suggestion?.color) {
      this.hostStyles = {
        "--main-color": this._suggestion.color,
        "--lightened-main-color": this._suggestion.color + "13",
      };
    }
  }
}
