<span
  class="d-inline-block mb-1"
  aria-hidden="true"
  data-testid="user-name"
  i18n="@@chat.message.from.user"
  >-</span
>
<span class="visually-hidden" i18n="@@chat.message.from.user.visually-hidden">-</span>
<br />
<span
  class="d-inline-block py-2 fs-6 lh-sm text-break"
  style="color: var(--fof-primary-bg-text-color)"
  [ngStyle]="style"
  [ngClass]="{
    'rounded-3 rounded-top-end-1 px-2 text-start': message.userWritten,
    'rounded-pill px-3 text-center': !message.userWritten
  }"
  data-testid="user-message"
  [innerHTML]="message.text | cleanhtml | iconize | linebreak"
></span>
