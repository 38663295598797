import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

export type FormButtonType = "primary" | "primary-text" | "secondary" | "secondary-text";

const filled = `
  --main-box-shadow: var(--bs-box-shadow-sm);
  --bs-btn-color: white;
  --bs-btn-bg: var(--fof-primary-color);
  --bs-btn-border-color: var(--fof-primary-color);
  --bs-btn-disabled-bg: var(--fof-disabled-bg-color); 
  --bs-btn-disabled-border-color: var(--fof-disabled-bg-color);
    `;
const etched = `
  --main-box-shadow: var(--bs-box-shadow-sm);
  --bs-btn-color: var(--fof-primary-color);
  --bs-btn-bg: rgba(var(--fof-primary-color-rgb), 0.08);
  --bs-btn-border-color: var(--fof-primary-color);
  --bs-btn-disabled-color: var(--fof-disabled-bg-color); 
  --bs-btn-disabled-bg: white; 
  --bs-btn-disabled-border-color: var(--fof-disabled-bg-color);
    `;
const texted = `
  --main-box-shadow: none;
  --bs-btn-color: var(--fof-primary-color);
  --bs-btn-bg: white;
  --bs-btn-border-color: white;
  --bs-btn-disabled-bg: transparent; 
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-disabled-color: var(--fof-disabled-color);
  `;
const filledOnHover = `
  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: var(--fof-primary-color);
  --bs-btn-hover-border-color: var(--fof-primary-color);
    `;
const etchedOnHover = `
  --bs-btn-hover-color: var(--fof-primary-color);
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: var(--fof-primary-color);
    `;
const filledOnActive = `
  --bs-btn-active-color: white; 
  --bs-btn-active-bg: var(--fof-primary-color);
  --bs-btn-active-border-color: var(--fof-primary-color);
`;
const availableStyles = {
  primary: `${filled} ${etchedOnHover} ${filledOnActive}`,
  "primary-text": `${texted} ${etchedOnHover} ${filledOnActive}`,
  secondary: `${etched} ${filledOnHover} ${filledOnActive}`,
  "secondary-text": `${texted} ${filledOnHover} ${filledOnActive}`,
};

/** A form button with specific styles */
@Component({
  selector: "button[formButton]",
  templateUrl: "./template.html",
  styleUrls: ["./style.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormButtonComponent {
  @HostBinding("style") hostStyles = "";
  @HostBinding("class") hostClasses = "btn btn-primary rounded-pill";

  @Input()
  public set formButton(formButtonType: FormButtonType) {
    this.hostStyles = availableStyles[formButtonType] ?? "";
  }
}
