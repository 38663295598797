<ng-container *ngFor="let message of history()">
  <article
    *ngIf="asUserText(message) as userTextMessage"
    [userMessage]="userTextMessage"
    class="mt-2 mb-4"
    data-testid="user-text-message"
  ></article>

  <article
    *ngIf="asBotText(message) as botTextMessage"
    [botMessage]="botTextMessage"
    class="mb-3 me-5"
    data-testid="bot-text-message"
  ></article>

  <img
    *ngIf="asImage(message) as imageMessage"
    [message]="imageMessage"
    alt="image"
    class="my-3"
    data-testid="image-message"
  />
</ng-container>
