import { InjectionToken, inject } from "@angular/core";
import { APP_CONFIG, AppHostService } from "app/core";
import { AvailabilityStore } from "./availability.store";
import { MaintenanceApiClient } from "./maintenance.apiclient";
import { HttpClient } from "@angular/common/http";
import { AvailabilityStoreMaintenanceImpl } from "./availability-maintenance.store";
import { AvailabilityStoreNoCheckImpl } from "./availability-nocheck.store";
import { USER_STORE } from "app/user";

/** Dependency injection tokens */
export const AVAILABILITY_STORE = new InjectionToken<AvailabilityStore>("AVAILABILITY_STORE", {
  factory: () => {
    const config = inject(APP_CONFIG);
    if (config.checkAvailability) {
      const maintenanceApiClient = new MaintenanceApiClient(
        config.backendBaseUrl + "info/maintenance",
        inject(HttpClient)
      );
      return new AvailabilityStoreMaintenanceImpl(
        config,
        inject(AppHostService),
        maintenanceApiClient,
        inject(USER_STORE)
      );
    }
    return new AvailabilityStoreNoCheckImpl(inject(USER_STORE));
  },
});
