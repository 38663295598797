import { Injectable } from "@angular/core";
import { UserStore } from "./user.store";

/** A service used to access the IDRH of the user */
@Injectable()
export class UserStoreDefaultImpl implements UserStore {
  public get() {
    // dummy object
    return { idrh: "", token: "" };
  }
  isUserValid() {
    return true;
  }
  isMissingRequiredUser() {
    return false;
  }
}
