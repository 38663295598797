/** Utility to manually handle a counter, enabling to decrement or recharge a counter. */
export class CountDown {
  private value = 0;

  constructor(private maxValue: number) {
    if (this.maxValue < 0) {
      this.maxValue = 0;
    }
  }

  /** Increases the counter to the maximum value */
  public recharge() {
    this.value = this.maxValue;
  }

  /**
   * Decrements the counter, if possible.
   * @return Whether the counter was decremented.
   */
  public countDown(): boolean {
    if (this.value <= 0) {
      return false;
    }
    this.value--;
    return true;
  }
}
