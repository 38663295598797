<hr style="border-color: var(--fof-border-color)" class="mb-4 opacity-100" />
<p i18n="@@satisfaction.comment.question">-</p>

<p class="mb-2">
  <textarea
    data-testid="chat-comment-textarea"
    rows="5"
    class="form-control border rounded-3"
    style="--bs-border-color: var(--fof-border-color)"
    autocomplete="off"
    placeholder="-"
    i18n-placeholder="@@satisfaction.comment.hint"
    aria-label="-"
    i18n-aria-label="@@satisfaction.comment.question"
    maxlength="4000"
    [requestFocus]="true"
    [(ngModel)]="userText"
  ></textarea>
</p>
<p class="mb-5 d-flex align-items-center justify-content-end gap-2">
  <button
    data-testid="chat-comment-cancel-button"
    type="button"
    formButton="secondary-text"
    (click)="onCancel($event)"
    i18n="@@satisfaction.comment.message-for-cancel"
  >
    -
  </button>
  <button
    data-testid="chat-comment-submit-button"
    type="button"
    formButton="secondary"
    (click)="onSubmit($event)"
    [disabled]="!userText"
    i18n="@@button.send"
  >
    -
  </button>
</p>
