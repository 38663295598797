import { Pipe, PipeTransform } from "@angular/core";

/** A pipe used to transform spans with a class into links */
@Pipe({ name: "toActions", standalone: true })
export class ToActionsPipe implements PipeTransform {
  transform(value: string, ..._args: unknown[]): string {
    let result = value;
    if (result) {
      result = result.replace(/<span class=/g, '<span role="button" tabindex="0" value=');
    }
    return result;
  }
}
