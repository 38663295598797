import { Inject, Injectable } from "@angular/core";
import { UserStore, USER_STORE } from "app/user";
import { Observable, BehaviorSubject } from "rxjs";
import { AvailabilityState, AvailabilityStore } from "./availability.store";

/** An implementation for which the application is always available */
@Injectable()
export class AvailabilityStoreNoCheckImpl implements AvailabilityStore {
  private _available$ = new BehaviorSubject<AvailabilityState>(undefined);

  constructor(@Inject(USER_STORE) private userStore: UserStore) {}
  /** @return Whether the application is available for the current user */
  get available$(): Observable<AvailabilityState> {
    return this._available$.asObservable();
  }

  initialize() {
    if (this.userStore.isMissingRequiredUser()) {
      this._available$.next("no");
      console.error("Missing required user");
    } else {
      this._available$.next("yes");
    }
  }
}
