import { ChangeDetectionStrategy, Component, HostBinding, Signal } from "@angular/core";
import { OutgoingMessageService } from "../../outgoing";
import { MessagesStore } from "../messages.store";
import { UserInputStateStore } from "./user-input-state.store";
import { RequestFocusDirective } from "app/core/ui/request-focus.directive";
import { FormsModule } from "@angular/forms";
import { IconButtonComponent } from "app/core/ui";

/**
 * The text input in which the user enters his messages.
 *
 * Activated only if:
 * - The user has the right to write
 * - The conversation is not locked
 * - The messages thread is displayed.
 *
 * Gains focus automatically if the last displayed message in the chat box should gain focus.
 */
@Component({
  selector: "fof-user-input",
  templateUrl: "./template.html",
  styleUrls: ["./style.scss"],
  imports: [FormsModule, RequestFocusDirective, IconButtonComponent],
  providers: [UserInputStateStore],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInputComponent {
  @HostBinding("class") hostClasses =
    "d-flex justify-content-between align-items-center flex-nowrap gap-2 rounded-bottom-5";

  protected userText = "";
  protected readonly requestFocus: Signal<number | null>;
  protected readonly isTextTypingDisabled: Signal<boolean>;

  constructor(
    stateStore: UserInputStateStore,
    private outgoingMessageService: OutgoingMessageService,
    private messagesStore: MessagesStore
  ) {
    this.requestFocus = stateStore.requestFocus;
    this.isTextTypingDisabled = stateStore.isTextTypingDisabled;
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    const text = this.userText.trim();
    if (text) {
      this.outgoingMessageService.sendTextMessage(text);
      this.messagesStore.nextTemporaryUserMessage(text, true, "");
    }
    this.userText = "";
  }
}
