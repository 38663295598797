import { Inject, Injectable } from "@angular/core";
import { Observable, Subject, filter, map, tap } from "rxjs";
import { IncomingConversationBlock } from "./connector";
import { CONVERSATION_LOCK } from "../di-tokens";
import { IncomingConverter } from "./converter/incoming.converter";
import { LockUtil } from "app/core";
import { CurrentConversationBlock } from "app/chat/common/message.model";
import { MessagesFetcher } from "./connector/messages.fetcher";
import { ScriptsExtractor } from "./scripts.extractor";
import { MESSAGES_FETCHER } from "./connector/di-tokens";

/**
 * Router for incoming messages. Analyzes each incoming message and forwards its
 * data (message, script, animation) to their observers.
 */
@Injectable({ providedIn: "root" })
export class IncomingRouter {
  /** The subject into which retrieved conversation blocks from the server are pushed */
  private _conversationBlocks$ = new Subject<CurrentConversationBlock>();
  /** The subject into which the scripts (denoted by their ID) to be executed, are pushed */
  private _scriptIds$ = new Subject<string>();

  constructor(
    @Inject(MESSAGES_FETCHER) private messagesFetcher: MessagesFetcher,
    private incomingConverter: IncomingConverter,
    private scriptsExtractor: ScriptsExtractor,
    @Inject(CONVERSATION_LOCK) private conversationLock: LockUtil
  ) {
    // adapts the conversation blocks received from the backend
    this.messagesFetcher
      .get()
      .pipe(
        // extract script IDs
        tap((block: IncomingConversationBlock) =>
          this.scriptsExtractor.extractScriptIds(block).forEach((id) => this._scriptIds$.next(id))
        ),
        map((block: IncomingConversationBlock) => this.incomingConverter.toCurrentBlock(block)),
        filter((block) => block.messages.length > 0),
        tap(() => this.conversationLock.unlock())
      )
      .subscribe(this._conversationBlocks$);
  }

  /**
   * @return The stream of the current conversation blocks.
   *         The blocks received before calling this method will not
   *         be available, unless it is the first time this method is called.
   */
  get conversationBlocks$(): Observable<CurrentConversationBlock> {
    return this._conversationBlocks$.asObservable();
  }

  /** @return The stream of the script IDs to be executed, for the current conversation */
  get scriptIds$(): Observable<string> {
    return this._scriptIds$.asObservable();
  }
}
