import { Injectable, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig } from "../core";
import { ConversationPrefsStore, HistoryStore } from "../chat";
import { SwitcherExtraInformation } from "app/common";
import { DimeloService } from "./dimelo.service";

/** Handles the switch to Dimelo */
@Injectable({ providedIn: "root" })
export class DimeloSwitcher {
  private triggerId: string;

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private historyStore: HistoryStore,
    private conversationPrefsStore: ConversationPrefsStore,
    private dimeloService: DimeloService
  ) {
    this.triggerId = config.dimeloTriggerId;
  }

  public switch(extraInformation: SwitcherExtraInformation = {}): void {
    this.dimeloService.display(
      this.historyStore.textHistory,
      this.conversationPrefsStore.get().keepOnServer,
      this.triggerId,
      extraInformation?.personalInfo
    );
  }
}
