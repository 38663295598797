import { ChangeDetectionStrategy, Component } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { PersonalInfo, PersonalInfoStore } from ".";
import { NgIf } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormButtonComponent } from "app/core/ui/form-button-component";

@Component({
  selector: "fof-personal-info",
  templateUrl: "./template.html",
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    FormButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/** A component displaying a form to obtain user information (name, email, employer etc..) */
export class PersonalInfoComponent {
  personalInfoForm = new FormGroup({
    name: new FormControl(""),
    firstname: new FormControl(""),
    id: new FormControl(""),
    employer: new FormControl(""),
    email: new FormControl("", [Validators.email]),
  });

  constructor(private personalInfoStore: PersonalInfoStore) {}

  onSubmit() {
    if (this.personalInfoForm.invalid) {
      return;
    }
    this.personalInfoStore.publish(this.personalInfoForm.value as PersonalInfo);
  }

  onCancel() {
    this.personalInfoForm.reset();
    this.personalInfoStore.cancelRequest();
  }
}
