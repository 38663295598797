import { AppConfig } from "app/core";
import { commonEnvironment } from "./environment-common";
import { signal } from "@angular/core";

export const environment: AppConfig = {
  ...commonEnvironment,
  botName: "clickrh",
  backendBaseUrl: "https://ui-api.chatbotrh-preprod.probayes.net/v0/",

  checkAvailability: false,
  hasMaintenance: false,

  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 180,
    closedWidth: 180,
  },

  hasConfigurationFromBackend: true,
  idrhSupport: { required: false },
  hasResetConversationOnReload: false,

  headerLogoUrl: "/assets/specific/clickrh/avatar.svg",
  headerBanner: { text: signal<string>("") },
  reducedBotLogoUrl: "/assets/specific/clickrh/avatar-circled.svg",
  reducedBotHasWelcomeText: true,

  hasCustomButtonColor: true,
  delayInConversationMessagesDisplayInMs: 0,
  scrollOnMessages: true,

  messageAvatarUrl: "/assets/specific/clickrh/avatar.svg",
};
