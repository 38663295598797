import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class WindowFacade {
  get window() {
    return window;
  }

  get navigator() {
    return window.navigator;
  }

  matchMedia(arg: string) {
    return window.matchMedia(arg);
  }
}
