import { BotTextMessage, UserTextMessage } from "app/chat";
import { DimeloAnonymizer } from "./dimelo.anonymizer";
import { DimeloConnector } from "./dimelo.connector";
import { DimeloMessageFactory } from "./dimelo-message.factory";
import { Injectable } from "@angular/core";
import { PersonalInfo } from "app/common";

/** The main service, enabling to activate the Dimelo chat box */
@Injectable({ providedIn: "root" })
export class DimeloService {
  constructor(
    private messageFactory: DimeloMessageFactory,
    private anonymizer: DimeloAnonymizer,
    private connector: DimeloConnector
  ) {}

  /**
   * Switches to the Dimelo chat. All the chat messages are sent to
   * Dimelo so that the Dimelo agent can have a history of the conversation
   * between the user and the chatbot.
   * Personal information is only sent if the user accepted to send it, and
   * are added after the last user message.
   */
  display(
    chatMessages: Array<UserTextMessage | BotTextMessage>,
    keepConversation: boolean,
    triggerId: string,
    personalInfo?: PersonalInfo
  ) {
    let dimeloMessages = this.messageFactory.arrayFrom(
      chatMessages,
      keepConversation ? personalInfo ?? null : null
    );
    if (!keepConversation) {
      dimeloMessages = this.anonymizer.execute(dimeloMessages);
    }
    this.connector.launchDimeloChat(triggerId, dimeloMessages);
  }
}
