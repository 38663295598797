import { ChangeDetectionStrategy, Component, HostBinding, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig, AppHostService } from "app/core";
import { NgIf } from "@angular/common";
import { ChatOpenStore } from "app/chat";
import { IconButtonComponent } from "app/core/ui";

@Component({
  selector: "fof-reduced-bot",
  templateUrl: "./template.html",
  standalone: true,
  imports: [NgIf, IconButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**Component displayed when bot is reduced*/
export class ReducedBotComponent {
  protected src: string;
  protected hasBubble = true;
  protected showBubble = true;

  @HostBinding("style") hostStyles = `font-size: var(--fof-reduced-bot-font-size);`;
  @HostBinding("class") hostClasses =
    "h-100 d-flex flex-column align-items-end justify-content-end border-0 bg-transparent p-2";

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private chatOpenStore: ChatOpenStore,
    protected host: AppHostService
  ) {
    this.src = config.distLocation + config.reducedBotLogoUrl;
    this.hasBubble = config.reducedBotHasWelcomeText;
  }

  openChat() {
    this.chatOpenStore.open();
  }

  closeBubble(event: Event) {
    event.preventDefault();
    this.showBubble = false;
  }
}
