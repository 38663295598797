import { Directive, ElementRef, Input } from "@angular/core";
import { AppHostService } from "../app-host.service";
import { FocusRequestExecutor } from "./focus-request.executor";

/** A directive to focus an HTML element. The request is an integer ID, that should be unique. */
@Directive({
  selector: "[requestFocus]",
  standalone: true,
})
export class RequestFocusDirective {
  /** The DOM element that should receive the focus */
  private element: HTMLInputElement;

  constructor(
    el: ElementRef<HTMLInputElement>,
    private hostService: AppHostService,
    private focusRequestExecutor: FocusRequestExecutor
  ) {
    this.element = el.nativeElement;
  }

  /** Request the focus on the element by defining the ID of the request */
  @Input()
  set requestFocus(request: number | boolean | null | undefined) {
    if (request && !this.hostService.isMobile) {
      this.focusRequestExecutor.next(this.element);
    }
  }
}
