import {
  AfterViewChecked,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
} from "@angular/core";
import { ScrollRequest } from "./scroll-request";
import { timer } from "rxjs";
import { AUTOSCROLL_BEHAVIOR } from "./di-tokens";

/**
 * A directive to automatically scroll to an anchor that can be found in the descendants of
 * the DOM element onto which the directive is defined. */
@Directive({
  selector: "[autoscroll]",
  standalone: true,
})
export class AutoScrollDirective implements AfterViewChecked {
  private element;
  private scrollRequest: ScrollRequest | null = null;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    el: ElementRef<HTMLElement>,
    @Inject(AUTOSCROLL_BEHAVIOR) private autoScrollBehavior: ScrollBehavior
  ) {
    this.element = el.nativeElement;
  }

  /** Defines the new anchor onto which the scrolling should occur */
  @Input()
  set autoscroll(newRequest: ScrollRequest | null) {
    // asynchronous to make sure the HTML element can be scrolled to
    timer(0).subscribe(() => {
      this.scrollRequest = newRequest;
      this.changeDetectorRef.markForCheck();
    });
  }

  /**
   * The scrolling is done once the view is rendered so that the HTML element
   * to scroll to exists
   */
  ngAfterViewChecked(): void {
    if (this.scrollRequest) {
      if (this.scrollRequest.anchor) {
        const elementWithAnchor = this.element.querySelector("#" + this.scrollRequest.anchor);
        const elementToScroll = elementWithAnchor || this.element;
        elementToScroll.scrollIntoView({
          behavior: this.autoScrollBehavior,
          block: this.scrollRequest.position,
          inline: "nearest",
        });
      }
    }
    this.scrollRequest = null;
  }
}
