import { ChangeDetectionStrategy, Component } from "@angular/core";

/** A component used to indicate to the user that a remote person/bot is writing */
@Component({
  selector: "fof-typing-indicator",
  templateUrl: "./template.html",
  styleUrls: ["./style.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypingIndicatorComponent {}
