import { Injectable } from "@angular/core";
import { DimeloMessage } from "./dimelo.model";

/** Anonymizes Dimelo messages from users */
@Injectable({ providedIn: "root" })
export class DimeloAnonymizer {
  /**
   * Modifies the passed messages array, filtering on the user ones, and
   * replacing their body content with the passed replacement text
   */
  execute(messages: Array<DimeloMessage>) {
    return messages.map((message) => (message.agent ? message : this.anonymizeMessage(message)));
  }

  /** @return A dimelo message with body modified */
  private anonymizeMessage(message: DimeloMessage): DimeloMessage {
    message.body = "<message supprimé>";
    return message;
  }
}
