import { ChangeDetectionStrategy, Component, HostBinding, Inject } from "@angular/core";
import { AppConfig, APP_CONFIG } from "app/core";
import { LottieComponent } from "../lottie-component/index";
import { NgIf } from "@angular/common";

/** Logo in header, animated or not depending on config file*/
@Component({
  selector: "fof-header-logo",
  templateUrl: "./template.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LottieComponent],
})
export class HeaderLogoComponent {
  @HostBinding("attr.aria-hidden") hostAriaHidden = "true";

  protected hasAnimation: boolean;
  protected logoUrl = "";

  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    this.hasAnimation = !!config.headerLogoAnimations;
    this.logoUrl = config.distLocation + config.headerLogoUrl;
  }
}
