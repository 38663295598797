import { Injectable } from "@angular/core";
import { DimeloMessage } from "./dimelo.model";
import { DimeloStateStore } from "./dimelo-state.store";

// Typescript
declare const _chatq: {
  push: any;
};

// Dimelo can be of 2 types: Dimelo A or Dimelo B
declare const Dimelo: DimeloA | DimeloB;

interface DimeloA {
  Messaging?: never;
  Chat: {
    Manager: {
      ChatQLoader: {
        manager: {
          triggers: Array<any>;
          activateTrigger: any;
        };
      };
    };
  };
}
interface DimeloB {
  Chat?: never;
  Messaging: {
    Manager: {
      ChatQLoader: {
        manager: {
          triggers: Array<any>;
          activateTrigger: any;
        };
      };
    };
  };
}

/** Abstraction to the Dimelo API */
@Injectable({ providedIn: "root" })
export class DimeloConnector {
  private underlying: { push: any } = [];

  constructor(private dimeloStateStore: DimeloStateStore) {}

  /** Send all messages to dimelo after chat is loaded  */
  sendAllMessages(messages: DimeloMessage[]) {
    messages.forEach((message) => {
      this.sendMessageToDimeloChat(message);
    });
  }

  /** Send message to dimelo  */
  private sendMessageToDimeloChat(message: DimeloMessage) {
    this.underlying.push(["_addMessage", message]);
  }

  launchDimeloChat(trigger_id: string, dimeloMessages: DimeloMessage[]): void {
    if (typeof _chatq !== "undefined") {
      this.underlying = _chatq;
    } else {
      this.underlying = [];
    }
    //Be careful if you move this event listerner : it has to be set early enough to catch the event.
    this.underlying.push(["_onEvent", "chat_shown", () => this.sendAllMessages(dimeloMessages)]);

    if (typeof Dimelo === "undefined") {
      console.debug("Dimelo chat is not available");
      return;
    }
    let manager:
      | {
          triggers: any[];
          activateTrigger: any;
        }
      | undefined = undefined;
    // different loaders may have different objects names
    if (Dimelo.Chat) {
      manager = Dimelo.Chat.Manager.ChatQLoader.manager;
    } else if (Dimelo.Messaging) {
      manager = Dimelo.Messaging.Manager.ChatQLoader.manager;
    }
    if (manager) {
      // Get all activated trigger objects
      const triggers = manager.triggers ?? [];
      // Find the good one
      const target_trigger = triggers.find((e) => e.id == trigger_id);
      if (target_trigger && !this.dimeloStateStore.isDimeloChatDisplayed) {
        // Hack - Activate a dedicated trigger
        manager.activateTrigger(target_trigger);
      }
    }
  }
}
