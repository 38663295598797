import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BackendConfig } from "./backend-config.model";
import { AppHostService } from "../../app-host.service";

/** Retrieve the config from the backend application */
@Injectable({ providedIn: "root" })
export class BackendConfigApiClient {
  botParams = "";
  constructor(
    private http: HttpClient,
    hostService: AppHostService
  ) {
    this.botParams = hostService.isTestBot ? "test" : "prod";
  }

  /** @return The config from the backend application */
  public retrieveConfig(endPoint: string): Observable<BackendConfig> {
    //Note : bot is not a query params because it is after ui-config/
    return this.http.get<BackendConfig>(`${endPoint}?bot=${this.botParams}`);
  }
}
