import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { BotAvatarDirective } from "../bot-avatar.directive";
import { TypingIndicatorComponent } from "app/core/ui";

/**
 * Displays the typing indicator for the bot.
 */
@Component({
  selector: "fof-bot-typing-indicator",
  templateUrl: "./template.html",
  standalone: true,
  imports: [BotAvatarDirective, TypingIndicatorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotTypingIndicatorComponent {
  @HostBinding("class") hostClasses = "d-flex align-items-center";
}
