<fof-header-logo data-testid="logo" />
<div class="flex-grow-1 d-flex flex-column">
  <h1
    data-testid="title"
    class="p-0 m-0 fw-bold fs-5"
    style="color: var(--fof-header-title-color)"
    i18n="@@header.title"
  >
    -
  </h1>
  <p
    data-testid="description"
    class="p-0 m-0 fs-6"
    style="color: var(--fof-header-message-color)"
    i18n="@@header.message"
  >
    -
  </p>
</div>
<div role="toolbar" class="d-flex align-items-end gap-1">
  <ng-container *ngFor="let action of actions()">
    <button
      [attr.data-testid]="'button-action-' + action.type"
      type="button"
      [icon]="action.iconType"
      [etched]="buttonsStyles"
      (click)="action.callable()"
      [attr.title]="action.label"
    ></button>
  </ng-container>

  <button
    class="ms-2"
    data-testid="button-close"
    type="button"
    icon="close"
    [etched]="buttonsStyles"
    (click)="chatOpenStore.close()"
    title="-"
    i18n-title="@@header.close"
  ></button>
</div>
