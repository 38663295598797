import { Injectable, Signal, signal } from "@angular/core";
import { ScrollRequest } from "app/core/ui";

/**
 * A service to trigger automatic scrolling on the messages box.
 * Each event contains the anchor to scroll to.
 */
@Injectable({ providedIn: "root" })
export class MessagesScrollRequester {
  /** A current value is required so that when reopening the chatbox, automatic scrolling is done to the last message. */
  private readonly _requests = signal<ScrollRequest>(new ScrollRequest("", "end"));

  get requests(): Signal<ScrollRequest> {
    return this._requests;
  }

  next(scrollRequest: ScrollRequest): void {
    this._requests.set(scrollRequest);
  }
}
