import { Pipe, PipeTransform } from "@angular/core";

/** A pipe to transform line feed characters (\n) into <br/> tags */
@Pipe({
  name: "linebreak",
  standalone: true,
})
export class LineBreakPipe implements PipeTransform {
  transform(value: string, ..._args: unknown[]): string {
    let result = value;
    if (result) {
      result = result.replace(/\n/g, "<br>");
    }
    return result;
  }
}
