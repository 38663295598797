import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Signal,
  computed,
  signal,
} from "@angular/core";
import { NgFor, NgIf } from "@angular/common";
import { IconButtonComponent } from "app/core/ui";
import { APP_CONFIG, AppConfig } from "app/core";

/** The header of the chat box. */
@Component({
  selector: "[headerBanner]",
  templateUrl: "./template.html",
  styleUrls: ["./style.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, IconButtonComponent],
})
// TODO test
export class HeaderBannerComponent {
  protected hasBanner = signal(false);
  protected readonly text: Signal<string>;
  protected shouldShow = computed(() => this.hasBanner() && this.text());

  @HostBinding("class") hostClasses = "border border-top-0 align-items-center gap-3 p-3 d-flex";
  @HostBinding("class.d-none") get shouldHide() {
    return !this.shouldShow();
  }

  @HostBinding("style") styleClasses = `position: relative;
     border-left-color: var(--fof-header-banner-border-x-color) !important; 
     border-right-color: var(--fof-header-banner-border-x-color) !important; 
     border-bottom-color: var(--fof-header-banner-border-bottom-color) !important; 
     background-color: var(--fof-header-banner-bg-color);
     color: var(--fof-header-banner-text-color)`;

  constructor(@Inject(APP_CONFIG) appConfig: AppConfig) {
    if (appConfig.headerBanner) {
      this.hasBanner.set(true);
      this.text = appConfig.headerBanner.text;
    } else {
      this.text = signal("");
    }
  }
}
