import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { IconButtonType } from "./icon-button.model";

/** A button with an icon */
@Component({
  selector: "button[icon]",
  templateUrl: "./template.html",
  styleUrls: ["./style.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  @HostBinding("style") hostStyles = `
  --fof-icon-button-bg-color: white;
  --fof-icon-button-color: var(--fof-primary-color);
  --fof-icon-button-border-color: var(--fof-border-color);
  --fof-icon-button-hover-bg-color: var(--fof-primary-color);
  --fof-icon-button-hover-color: white;
  --fof-icon-button-hover-border-color: white;`;

  @HostBinding("class") hostClasses = "btn btn-outline-secondary rounded-circle text-center p-0";

  @Input()
  public icon: IconButtonType = "send";

  /**
   * If set, the button is displayed as "etched": no background, only the icon and the border are displayed.
   * @param newValue An object containg the color for the normal mode and the color for the hover mode.
   *                 CSS variables can be used, surrounded by the "var" keyword.
   *    */
  @Input()
  public set etched({
    color = "white",
    bgColor = "transparent",
    borderColor = "white",
    colorOnHover = "var(--fof-primary-color)",
    bgColorOnHover = "white",
    borderColorOnHover = "var(--fof-primary-color)",
  }: {
    color?: string;
    bgColor?: string;
    borderColor?: string;
    colorOnHover?: string;
    bgColorOnHover?: string;
    borderColorOnHover?: string;
  }) {
    this.hostStyles = `
    --fof-icon-button-bg-color: ${bgColor};
    --fof-icon-button-color: ${color};
    --fof-icon-button-border-color: ${borderColor};
    --fof-icon-button-hover-bg-color: ${bgColorOnHover};
    --fof-icon-button-hover-color: ${colorOnHover};
    --fof-icon-button-hover-border-color: ${borderColorOnHover};`;
  }
}
