import { Observable } from "rxjs";
import { Inject, Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { APP_CONFIG, AppConfig } from "app/core";
import { UserStore } from "./user.store";
import { USER_STORE } from "./di-tokens";

/** An HTTP interceptor that enriches each HTTP request of the application with user information */
@Injectable()
export class UserHttpInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(USER_STORE) private userStore: UserStore
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let nextRequest = request;
    if (this.config.idrhSupport) {
      nextRequest = request.clone({
        headers: request.headers.set("user-id", this.userStore.get().idrh),
      });
    }
    return next.handle(nextRequest);
  }
}
