import { Directive, HostBinding } from "@angular/core";

/** A button with a single character with a large font display */
@Directive({
  selector: "button[large]",
  standalone: true,
})
export class LargeButtonDirective {
  @HostBinding("style") hostStyles = `
  width: 1.8em;
  height: 1.8em;
  --bs-btn-font-weight: 500;
  --bs-btn-bg: white;
  --bs-btn-border-color: var(--fof-border-color);
  --bs-btn-hover-color: var(--fof-primary-color);
  --bs-btn-hover-border-color: var(--fof-primary-color);
  --bs-btn-hover-bg: rgba(var(--fof-primary-color-rgb), 0.08);
  `;

  @HostBinding("class") hostClasses = "btn btn-light btn-lg text-center fs-1 p-1";
}
