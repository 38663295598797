import { Inject, Injectable, Signal, signal } from "@angular/core";
import { APP_CONFIG, AppConfig, HeaderActionType } from "app/core";
import { HeaderAction } from "./header-action.model";
import { ChatLifecycleService, OutgoingMessageService } from "app/chat";

/** Defines the actions to be available in the header */
@Injectable()
export class HeaderActionsService {
  private readonly _actions = signal<HeaderAction[]>([]);

  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    outgoingMessageService: OutgoingMessageService,
    chatLifecycleService: ChatLifecycleService
  ) {
    const allActions = this.buildActions(outgoingMessageService, chatLifecycleService);
    this._actions.set(
      appConfig.headerActions.map((type) => allActions[type] || null).filter((action) => !!action)
    );
  }

  /** @return The actions for which a button should be displayed on the header */
  public get actions(): Signal<HeaderAction[]> {
    return this._actions;
  }

  private buildActions(
    outgoingMessageService: OutgoingMessageService,
    chatLifecycleService: ChatLifecycleService
  ) {
    type ResultType = { [key in HeaderActionType]: HeaderAction };

    return new Array<HeaderAction>(
      {
        type: "menu",
        label: $localize`:@@header.action.menu:`,
        iconType: "home",
        callable: () => outgoingMessageService.sendIntentionMessage("menu"),
      },
      {
        type: "restart",
        label: $localize`:@@header.action.restart:`,
        iconType: "refresh",
        callable: () => {
          outgoingMessageService.sendIntentionMessage("messenger_entrypoint");
          chatLifecycleService.reset();
        },
      },
      {
        type: "satisfaction",
        label: $localize`:@@header.action.satisfaction:`,
        iconType: "thumbs-up",
        callable: () => outgoingMessageService.sendIntentionMessage("bot_satisfaction_emoji"),
      }
    ).reduce((accumulator: ResultType, current) => {
      accumulator[current.type] = current;
      return accumulator;
    }, {} as ResultType);
  }
}
