import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EMPTY, Observable, catchError } from "rxjs";

/** Retrieve the animation data for a particular URL */
@Injectable()
export class AnimationApiClient<T> {
  constructor(private http: HttpClient) {}

  /**
   * @return The animation data, retrieved from the passed URL.
   *           If an error occurs, the observable completes with no failure.
   */
  public get(url: string): Observable<T> {
    return this.http.get<T>(url).pipe(
      catchError((error) => {
        console.error("Unable to retrieve animation data", error);
        return EMPTY; // on error, we don't want the animations stream to fail
      })
    );
  }
}
