/** A request to scroll to an HTML element */
export class ScrollRequest {
  /**
   * @param anchor ID of the HTML element to scroll to
   * @param position The position where to scroll in the block: start of the block or end of the block
   */
  constructor(
    public readonly anchor: string,
    public readonly position: "start" | "end" = "start"
  ) {}
}
