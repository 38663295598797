import { Injectable } from "@angular/core";
import { OutgoingMessageService } from "../../outgoing";
import { ConversationPrefsStore } from "../../prefs";

/** A service to execute the behavior associated to a chatbot text message clicked by the user */
@Injectable()
export class TextExecutor {
  actions = {
    intent_contacter_conseiller_formulaire_click: () =>
      this.outgoingMessageService.sendIntentionMessage("contacter_conseiller_formulaire_click"),
    decline_recording: () => {
      this.outgoingMessageService.sendIntentionMessage("declined_recording");
      this.conversationPrefsStore.setKeepOnServer(false);
    },
  };

  constructor(
    private outgoingMessageService: OutgoingMessageService,
    private conversationPrefsStore: ConversationPrefsStore
  ) {}

  /**
   * Executes the behavior associated to the passed action ID.
   * If the action is not supported, it is ignored
   * @param action The ID of the action to be executed
   */
  execute(action: string): void {
    if (action && this.actions.hasOwnProperty(action)) {
      const key = action as keyof typeof this.actions;
      this.actions[key]();
    }
  }
}
