import { Directive, HostBinding, Input } from "@angular/core";

/**Directive to add specific color on button (used on positive or negative evaluation button)*/
@Directive({
  selector: "button[specificColor]",
  standalone: true,
})
export class EvaluationButtonDirective {
  private buttonSize = "3em";
  private commonStyle = `
  --fof-evaluation-button-size: ${this.buttonSize};
  --bs-btn-border-color: var(--fof-border-color) !important;
  --bs-btn-hover-bg: var(--lightened-main-color) !important;
  --bs-btn-hover-border-color: var(--main-color) !important;
  --bs-btn-hover-color: var(--main-color) !important;
  --bs-btn-active-bg: var(--lightened-main-color) !important;
  --bs-btn-active-border-color: var(--lightened-main-color) !important;
  --bs-btn-active-color: var(--main-color) !important;
  --bs-btn-disabled-border-color: var(--fof-disabled-bg-color);
  --bs-btn-disabled-bg: white;
  --bs-btn-disabled-color: var(--fof-disabled-bg-color);
  width: var(--fof-evaluation-button-size);
  height: var(--fof-evaluation-button-size);`;

  @HostBinding("style") hostStyles = `${this.commonStyle}`;

  /** Main color to apply on button*/
  @Input() specificColor: string = "#008000";

  /** If true, button is still colored when disabled*/
  @Input() set keepColored(colored: boolean) {
    this.hostStyles = `
      --main-color: ${this.specificColor};
      --lightened-main-color: ${this.specificColor}14;
      ${this.commonStyle}`;

    if (colored) {
      this.hostStyles += `
      --bs-btn-disabled-border-color: var(--main-color) !important;
      --bs-btn-disabled-bg: var(--lightened-main-color) !important;
      --bs-btn-disabled-color: var(--main-color) !important;
      `;
    }
  }
}
