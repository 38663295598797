import { APP_INITIALIZER } from "@angular/core";
import { APP_CONFIG, AppParentService } from "app/core";
import { ChatOpenStore } from "./open";
import { MessagesStore } from "./current";
import { ConversationService, IncomingRouter, IncomingScriptExecutor } from "./incoming";
import { setupMessagesScroll } from "./common/messages-scroll.setup";
import { MessagesScrollRequester } from "./common/messages-scroll.requester";

/**
 * Initializes the chat observers.
 *
 * The following actions are executed when the open state changes:
 * - If the chatbot opens, listening to messages from the backend is triggered
 * - The parent container is notified of the current state
 */
export function setupChatObservers(
  chatOpenStore: ChatOpenStore,
  parentService: AppParentService,
  incomingRouter: IncomingRouter,
  incomingScriptExecutor: IncomingScriptExecutor,
  conversationService: ConversationService,
  messageStore: MessagesStore
) {
  return () => {
    // synchronize the chatbot open state with the parent container
    chatOpenStore.isOpen$.subscribe((isOpen: boolean) => parentService.setBotVisible(isOpen));
    // each time the bot is opened, remote messages should be retrieved
    chatOpenStore.isOpening$.subscribe(() => conversationService.awaitIncomingMessages());
    incomingRouter.scriptIds$.subscribe((scriptId) => incomingScriptExecutor.execute(scriptId));
    incomingRouter.conversationBlocks$.subscribe((currentBlock) => messageStore.next(currentBlock));
  };
}

export function setupChat() {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: setupChatObservers,
      multi: true,
      deps: [
        ChatOpenStore,
        AppParentService,
        IncomingRouter,
        IncomingScriptExecutor,
        ConversationService,
        MessagesStore,
      ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupMessagesScroll,
      multi: true,
      deps: [APP_CONFIG, MessagesStore, MessagesScrollRequester],
    },
  ];
}

export { ChatLifecycleService } from "./chat-lifecycle.service";
export { UserTextMessage, BotTextMessage, ImageMessage } from "./common/message.model";
export { HistoryStore } from "./history";
export { ConversationPrefsStore } from "./prefs";
export { OutgoingMessageService } from "./outgoing";
export { ChatOpenStore } from "./open";
export { CONVERSATION_LOCK } from "./di-tokens";
